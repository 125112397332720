import { COUNTRIES } from "../constants/countries.const";

export default class DataService {
    countries = COUNTRIES.filter(it => it.territoryType === 'Country')
    processMarkedCountries(list: any) {
        let markedCountries = this.countries.filter(it => list.territories.includes(it.alpha3Code));
        let markedWorldRegions = Array.from(new Set(markedCountries.map(it => it.territoryWRegion))).sort()
        let ob = new Map()
        for (let cont of markedWorldRegions) {
            ob.set(cont, {
                countries: markedCountries.filter(it => it.territoryWRegion === cont),
                totalRegionCount: COUNTRIES.filter(it => it.territoryType === 'Country' && it.territoryWRegion === cont).length
            })
        }
        ob.set('flattened', markedCountries)

        return ob
    }

    prepareStats(countryList: any, userData: any, viewType: string) {
        try {
            if (!countryList) {
                return
            }

            if (viewType === 'visited') {
                return [
                    {
                        name: "countriesCount", value: countryList?.get('flattened').length + '/195', desc: countryList?.get('flattened').length === 1 ? 'country' : 'countries'
                    },
                    {
                        name: 'percentOfTheWorld', value: ((countryList?.get('flattened').length / 195) * 100).toFixed(0).toString() + '%', desc: 'of the world'
                    },
                    {
                        name: "worldRegionsCount", value: countryList?.size - 1 + '/10', desc: countryList?.size === 1 ? 'world regions' : 'world regions'
                    },
                    {
                        name: "citiesCount", value: userData.visited.cities.length, desc: userData.visited.cities.length === 1 ? 'city' : 'cities'
                    },
                    {
                        name: "regionsCount", value: userData.visited.regions.length, desc: userData.visited.regions.length === 1 ? 'region' : 'regions'
                    },
                    {
                        name: "livedCount", value: userData.wil.territories.length, desc: userData.wil.territories.length === 1 ? 'country lived' : 'countries lived'
                    }
                ]
            } else {
                return [
                    {
                        name: 'countries', value: countryList?.get('flattened').length, desc: countryList?.get('flattened').length === 1 ? 'country' : 'countries'
                    },
                    {
                        name: 'cities', value: userData.wantTo.cities.length, desc: userData.visited.cities.length === 1 ? 'city' : 'cities'
                    },
                    {
                        name: 'regions', value: userData.wantTo.regions.length, desc: userData.visited.regions.length === 1 ? 'region' : 'regions'
                    },
                ]
            }
        } catch (err) {
            console.log(err)
        }
    }

    prepareCityRegionData(data: any, countryList: any): any {
        let citiesAndRegions: any = {}
        for (let country of countryList) {
            citiesAndRegions[country.alpha3Code] = {};
            citiesAndRegions[country.alpha3Code]['cities'] = data.cities.filter(
                (it: string) => it.toLowerCase().slice(0, 3) === country.alpha3Code.toLowerCase()
            )
            citiesAndRegions[country.alpha3Code]['regions'] = data.regions.filter(
                (it: string) => it.toLowerCase().split('-')[0].toLowerCase() === country.alpha2Code.toLowerCase()
            )
        }
        return citiesAndRegions
    }
}
