import { Col, Divider, Row } from "antd";
import React from "react";

export class Stats extends React.Component<any, any> {
    render() {
        return <>
            <Row justify="center">
                {this.props.stats && this.props.stats.map(
                    (stat: any, index: number) => (
                        <React.Fragment key={index}>
                            <Col span={3} className="stat">
                                <Row justify="center" className="count">
                                    <Col span={24}>{stat.value}</Col>
                                </Row>
                                <Row justify="center" className="desc">
                                    <Col span={24}>{stat.desc}</Col>
                                </Row>
                            </Col>
                            {index + 1 !== this.props.stats.length && (
                                <Divider key={index + 10} type="vertical" className="divider" />
                            )}
                        </React.Fragment>
                    )
                )}
            </Row>
        </>
    }
}
