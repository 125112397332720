import { Col, Row } from "antd";
import React from "react";

export default class MainHeader extends React.Component<any, any> {
    getAppDownloadUrl() {
        if (['ios', 'iphone'].includes(this.props.os.toLowerCase())) {
            return "https://apps.apple.com/us/app/skratch-where-ive-been/id1457438876"
        } else if (this.props.os.toLowerCase() === "android") {
            return "https://play.google.com/store/apps/details?id=world.skratch.app"
        } else {
            return "https://www.skratch.world"
        }
    }

    render() {
        return <>
            <Row className="main-header-sub" justify="space-between">
                <Col className="logo-holder" sm={8} xs={{span: 12}}>
                    <img width="30px" height="30px" src={process.env.REACT_APP__APP_PATH_PREFIX + '/svg/skratch-logo-circle.svg'} alt="" />
                    <img className="logo-alpha" width="85px" height="20px" src={process.env.REACT_APP__APP_PATH_PREFIX + '/svg/skratch-logo-alpha.svg'} alt="" />
                </Col>
                <Col className="app-download fixed" sm={12} xs={24} lg={10} xl={9} xxl={7}>
                    <Row justify="space-between" align="middle">
                        <Col xs={15} sm={10} md={11} lg={13} xl={12} xxl={11} className="download-text">
                            <Row>
                                <Col className="hidden-sub-logo">
                                    <img width="30px" height="30px" src={process.env.REACT_APP__APP_PATH_PREFIX + '/svg/skratch-logo-circle.svg'} alt=""/>
                                </Col>
                                <Col xl={22} xs={16}>
                                    Want to build your own Skratch map?
                                </Col>
                            </Row>
                        </Col>
                        <Col className="download-link-btn">
                            <a href={this.getAppDownloadUrl()} className="button download-link-text" target="_blank" rel="noreferrer">
                                Get Skratch
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    }
}
