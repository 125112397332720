import { Col, Row, Image } from "antd";
import React from "react";

export default class ProfileComponent extends React.Component<any, any> {
    render() {
        return <>
            <Row justify="center" className="profile-photo">
                <Col span={6} className="profile-photo">
                    <Image width={48} height={48} src={this.props.userAvatar} preview={false}></Image>
                </Col>
            </Row>
            <Row justify="center">
                <Col className="user-name">{this.props.userName}</Col>
            </Row>
        </>
    }
}
