import { Col, Row, Spin } from "antd";
import React, { Component } from "react";
import { COUNTRIES } from "../constants/countries.const";
import DataService from "../services/DataService";
import UserService from "../services/UserService";
import ListingComponent from "./Listing";
import MainHeader from "./MainHeader";
import ProfileComponent from "./Profile";
import { Stats } from "./Stats";
import ViewPickerComponent from "./ViewPicker";
import { World } from "./World";
import tooltip from "wsdm-tooltip";

class Share extends Component<any, any> {
    userService = new UserService()
    dataService = new DataService()
    windowWidth = window.innerWidth;
    userData: any;
    tooltip: any;

    constructor(props: any) {
        super(props);
        this.onUpdateViewType = this.onUpdateViewType.bind(this);
        this.state = {
            os: this.get_platform(),
        }

        if (this.windowWidth > 800) {
            this.onHover = this.onHover.bind(this)
            this.onListingHover =
            this.onListingHover.bind(this)
        }
        
        if (!window.location.pathname.includes('visited') && !window.location.pathname.includes('want-to-visit')) {
            let path = window.location.pathname.split('/')
            let newPath = path.slice(0, 3).join('/') + '/visited'
            if (path.slice(3,).length) {
                newPath += '/' + path.slice(3,).join('/')
            }
            newPath += window.location.search;
            window.location.replace(newPath.replace('//', '/'));
        }
    };

    onUpdateViewType(userSecret: string, viewSelected: string): void {
        this.props.navigate(`/${userSecret}/${viewSelected}`)
        this.props.updateViewType(userSecret, viewSelected);
    }

    onHover(code: string | null, pos: any | null) {
        if (this.windowWidth <= 800) return;

        this.setState({ hoveredCountry: code })
        if (code) {
            let tooltipData = this.getTooltipData(code)
            let tooltipHtml = `
                <div class="tooltip-inner">
                        <div class="country-flag">
                            <img class="flag" wdith="40px" height="40px" src=${tooltipData.flagUrl} alt=${tooltipData.name}/>
                        </div>
                        <div class="info-content">
                            <div class="country-name">${tooltipData.name}</div>
            `
            if (tooltipData.cities) {
                tooltipHtml += '<div class="country-additional">'
                if (tooltipData.cities > 1) {
                    tooltipHtml += tooltipData.cities + ' cities'
                } else {
                    tooltipHtml += tooltipData.cities + ' city'
                }

            }
            if (tooltipData.regions) {
                if (tooltipData.cities) {
                    tooltipHtml += ' ∙ '
                }
                if (tooltipData.regions > 1) {
                    tooltipHtml += tooltipData.regions + ' regions'
                } else {
                    tooltipHtml += tooltipData.regions + ' region'
                }
            }

            tooltipHtml +=  `</div >
                    </div >`

            if (pos) {
                this.tooltip.position({ pageX: pos.x, pageY: pos.y - 15 })
            }
            
            if (tooltipData.name && tooltipData.flagUrl) {
                this.tooltip.show(tooltipHtml);
            }
        } else {
            this.tooltip.hide()
        }
    }

    onAdjustTooltipPosition = (x: any, y: any): any => {
        if (this.windowWidth <= 800) return;

        if (this.state.hoveredCountry) {
            this.tooltip.position({ pageX: x, pageY: y - 15 })
        }
    }

    // From Listing -> Sent To Map for geo calculation
    onListingHover(code: any) {
        if (this.windowWidth <= 800) return;

        let elBox = document.getElementById(code)?.getBoundingClientRect();
        if (elBox) {
            this.onHover(code, {x: elBox.left, y: elBox.y - 10})
        } else {
            this.onHover(code, {x: 0,y: 0})
        }
    }

    getTooltipFlag(code: any) {
        return process.env.REACT_APP__APP_PATH_PREFIX + '/flags/' + code + '.png';
        
    }

    getTooltipData(code: string) {
        return {
            name: COUNTRIES.find((it: any) => it.territoryType === 'Country' && it.alpha3Code === code)?.territoryName || null,
            flagUrl: this.getTooltipFlag(code),
            cities: this.state.citiesAndRegionsByCountry[code]?.cities?.length || null,
            regions: this.state.citiesAndRegionsByCountry[code]?.regions?.length || null
        }
    }

    componentDidMount() {
        this.userService.getMapData(this.props.match.params.userSecret).then(data => {
            this.userData = data;
            document.title = this.userData.name ? `${this.userData.name}'s Skratch Map` : "Skratch Map"
            if (this.props.match.params.viewType === 'visited') {
                let countryList = this.dataService.processMarkedCountries(this.userData.visited)
                this.setState({ citiesAndRegionsByCountry: this.dataService.prepareCityRegionData(this.userData.visited, countryList.get('flattened')) })
                this.setState({ countryList: countryList })
                this.setState({ stats: this.dataService.prepareStats(countryList, this.userData, this.props.match.params.viewType) })
            } else {
                let countryList = this.dataService.processMarkedCountries(this.userData.wantTo)
                this.setState({ citiesAndRegionsByCountry: this.dataService.prepareCityRegionData(this.userData.wantTo, countryList.get('flattened')) })
                this.setState({ countryList: countryList })
                this.setState({ stats: this.dataService.prepareStats(countryList, this.userData, this.props.match.params.viewType) })
            }
        },
            (err) => {
                console.log(err)
            }
        )
        
        this.tooltip = tooltip({
            styles: {
                "border-radius": "12px",
                "background": "#FFFFFF",
                "box-shadow": "0px 2px 8px rgba(0, 0, 0, 0.15)",
                "pointer-events": "none",
                "margin": "0px",
                "padding": "0px"
            }
        });
        
        this.tooltip.create();
    }

    get_platform() {
        // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
        if (typeof (navigator as any).userAgentData !== 'undefined' && (navigator as any).userAgentData != null) {
            return (navigator as any).userAgentData.platform;
        }
        // Deprecated but still works for most of the browser
        if (typeof navigator.platform !== 'undefined') {
            if (typeof navigator.userAgent !== 'undefined' && /android/.test(navigator.userAgent.toLowerCase())) {
                // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
                return 'android';
            }
            return navigator.platform;
        }
        return 'unknown';
    };


    render() {
        return <>
            <Row className="container">
                <Col xl={18} lg={16} md={15} sm={14} xs={24} className="main-section">
                    <Row justify="space-between" className="flex-container">
                        <Col>
                            <Row className="main-header" justify="space-between">
                                <Col span={24}>
                                    {this.state.os && (
                                        <MainHeader os={this.state.os}></MainHeader>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="world" justify="space-between">
                                <Col span={24}>
                                    {this.state.countryList && (
                                        <World tooltipContent={this.state.hoveredCountry} countryList={this.state.countryList} viewSelected={this.props.match.params.viewType} adjustTooltipPosition={this.onAdjustTooltipPosition} onHover={this.onHover}></World>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className={this.props.match.params.viewType === 'visited' ? "stats visited" : "stats want-to-visit"} justify="space-between">
                                <Col span={24} style={{ marginBottom: '40px' }}>
                                    {this.state.stats && (
                                        <Stats viewType={this.state.viewType} stats={this.state.stats}></Stats>
                                    )}

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xl={6} lg={8} md={9} sm={10} xs={24} className="sider">
                    <Row justify="center" className="profile-section">
                        <Col span={24}>
                            {!this.userData && (
                                <div style={{textAlign: "center", paddingTop: "25px", paddingBottom: "20px"}}>
                                    <Spin style={{ alignSelf: "center", textAlign: "center" }} />
                                </div>
                            )}
                            {this.userData && (
                                <ProfileComponent userAvatar={this.userData.avatar} userName={this.userData.name}></ProfileComponent>
                            )}
                        </Col>
                    </Row>
                    <Row justify="center" className="view-picker">
                        <Col span={24}>
                            <ViewPickerComponent userSecret={this.props.match.params.userSecret} viewSelected={this.props.match.params.viewType} onViewSelectedChange={this.onUpdateViewType}></ViewPickerComponent>
                        </Col>
                    </Row>
                    <Row justify="center" className="listing-side">
                        <Col span={24}>
                            {this.state.countryList && this.state.citiesAndRegionsByCountry && (
                                <ListingComponent viewSelected={this.props.match.params.viewType} countriesByWRegion={this.state.countryList} citiesAndRegionsByCountry={this.state.citiesAndRegionsByCountry} livedTerritoryCodes={this.userData.wil.territories} onListingHover={this.onListingHover} onHover={this.onHover} hoveredCountry={this.state.hoveredCountry}></ListingComponent>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    }
}

export default Share;
