export const COUNTRIES = [
    {
        territoryName: "Abkhazia",
        territoryAlternativeNames: "",
        territoryCountry: "Georgia",
        territoryWRegion: "Middle East",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GEO",
        alpha3Code: "GEOAB",
        state_code: "",
        skratchCode: "GEOAB",
        callingCodes: "7",
        lat: "43.0016",
        lng: "41.0234",
        SW: "42.414288, 39.879345",
        NE: "43.554223, 42.185186",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Afghanistan",
        territoryAlternativeNames: "",
        territoryCountry: "Afghanistan",
        territoryWRegion: "South Asia",
        territoryType: "Country",
        alpha2Code: "AF",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "AFG",
        state_code: "",
        skratchCode: "",
        callingCodes: "93",
        lat: "33.9391",
        lng: "67.7100",
        SW: "29.183934, 60.628291",
        NE: "38.515997, 74.141406",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Alabama",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAAL",
        state_code: "AL",
        skratchCode: "USAAL",
        callingCodes: "",
        lat: "32.3182",
        lng: "-86.9023",
        SW: "30.087162, -88.506547",
        NE: "35.322386, -85.122878",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Åland Islands",
        territoryAlternativeNames: "",
        territoryCountry: "Finland",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FIN",
        alpha3Code: "ALA",
        state_code: "",
        skratchCode: "",
        callingCodes: "358",
        lat: "60.1785",
        lng: "19.9156",
        SW: "59.890657, 19.504280",
        NE: "60.588117, 21.199552",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Alaska",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAAK",
        state_code: "AK",
        skratchCode: "USAAK",
        callingCodes: "",
        lat: "64.2008",
        lng: "-149.4937",
        SW: "53.969566, -164.033670",
        NE: "70.849671, -139.933972",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Albania",
        territoryAlternativeNames: "",
        territoryCountry: "Albania",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "AL",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ALB",
        state_code: "",
        skratchCode: "",
        callingCodes: "355",
        lat: "41.1533",
        lng: "20.1683",
        SW: "39.728419, 19.418802",
        NE: "42.767956, 20.898724",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "Total territory_name",
        field22: "311"
    },
    {
        territoryName: "Algeria",
        territoryAlternativeNames: "",
        territoryCountry: "Algeria",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "DZ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "DZA",
        state_code: "",
        skratchCode: "",
        callingCodes: "213",
        lat: "28.0339",
        lng: "1.6596",
        SW: "20.638830, -8.231542",
        NE: "37.220045, 12.520046",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "Total country",
        field22: "201"
    },
    {
        territoryName: "American Samoa",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "ASM",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "-14.303714",
        lng: "-170.728382",
        SW: "-14.382737, -170.870868",
        NE: "-14.086162, -169.346425",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "Total disputed territory",
        field22: "8"
    },
    {
        territoryName: "Andorra",
        territoryAlternativeNames: "",
        territoryCountry: "Andorra",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "AD",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "AND",
        state_code: "",
        skratchCode: "",
        callingCodes: "376",
        lat: "42.5063",
        lng: "1.5218",
        SW: "42.422451, 1.389540",
        NE: "42.677292, 1.817237",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "Total other territory",
        field22: "51"
    },
    {
        territoryName: "Angola",
        territoryAlternativeNames: "",
        territoryCountry: "Angola",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "AO",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "AGO",
        state_code: "",
        skratchCode: "",
        callingCodes: "244",
        lat: "-11.2027",
        lng: "17.8739",
        SW: "-18.076746, 11.043324",
        NE: "-5.739722, 23.528820",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "Total regions",
        field22: "11"
    },
    {
        territoryName: "Anguilla",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "AIA",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "18.2206",
        lng: "-63.0686",
        SW: "18.136658, -63.255404",
        NE: "18.347052, -62.899202",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Antarctica",
        territoryAlternativeNames: "",
        territoryCountry: "Antarctica",
        territoryWRegion: "Antarctica",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ATA",
        state_code: "",
        skratchCode: "",
        callingCodes: "672",
        lat: "-74.001814",
        lng: "4.97748",
        SW: "-90,-180",
        NE: "-60.51620859199994,180",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Antigua and Barbuda",
        territoryAlternativeNames: "",
        territoryCountry: "Antigua and Barbuda",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "AG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ATG",
        state_code: "",
        skratchCode: "",
        callingCodes: "1268",
        lat: "17.0608",
        lng: "-61.7964",
        SW: "16.991295, -61.967582",
        NE: "17.728272, -61.675902",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21:
            "Important:\n- UK has been divided into constituent countries\n- US has been divided into constituent states\n- Number of countries visited for sharing functionality will be based on territory_country count NOT territory_name\n- If a user visits a 'disputed territory', the relevant territory_country will also be marked as visited/shaded\n- If a user visits an 'other territory', just the territory_name will be marked as visited/shaded\n- My Location tab will be based on territory_name\n- country_code based on ISO 3166-1 alpha-3",
        field22: ""
    },
    {
        territoryName: "Argentina",
        territoryAlternativeNames: "",
        territoryCountry: "Argentina",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "AR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ARG",
        state_code: "",
        skratchCode: "",
        callingCodes: "54",
        lat: "-38.4161",
        lng: "-63.6167",
        SW: "-53.837987, -76.937721",
        NE: "-22.935519, -51.615680",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Arizona",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAAZ",
        state_code: "AZ",
        skratchCode: "USAAZ",
        callingCodes: "",
        lat: "34.0489",
        lng: "-111.0937",
        SW: "31.667185, -114.890541",
        NE: "37.362718, -108.624460",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Arkansas",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAAR",
        state_code: "AR",
        skratchCode: "USAAR",
        callingCodes: "",
        lat: "35.201",
        lng: "-91.8318",
        SW: "32.923301, -94.601314",
        NE: "36.776613, -89.642144",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Armenia",
        territoryAlternativeNames: "",
        territoryCountry: "Armenia",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "AM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ARM",
        state_code: "",
        skratchCode: "",
        callingCodes: "374",
        lat: "40.0691",
        lng: "45.0382",
        SW: "39.195429, 43.687515",
        NE: "41.262537, 46.341924",
        "Flag?": "No",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Artsakh",
        territoryAlternativeNames: "",
        territoryCountry: "Azerbaijan",
        territoryWRegion: "Middle East",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "AZE",
        alpha3Code: "AZEAR",
        state_code: "",
        skratchCode: "AZEAR",
        callingCodes: "994",
        lat: "39.7962",
        lng: "46.6551",
        SW: "39.184512, 45.964893",
        NE: "40.347326, 47.305841",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Aruba",
        territoryAlternativeNames: "",
        territoryCountry: "Netherlands",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "NLD",
        alpha3Code: "ABW",
        state_code: "",
        skratchCode: "",
        callingCodes: "297",
        lat: "12.5211",
        lng: "-69.9683",
        SW: "12.405116, -70.093094",
        NE: "12.617807, -69.849006",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Australia",
        territoryAlternativeNames: "",
        territoryCountry: "Australia",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "AU",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "AUS",
        state_code: "",
        skratchCode: "",
        callingCodes: "61",
        lat: "-25.2744",
        lng: "133.7751",
        SW: "-38.159909, 109.673101",
        NE: "-12.948934, 154.062267",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Austria",
        territoryAlternativeNames: "",
        territoryCountry: "Austria",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "AT",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "AUT",
        state_code: "",
        skratchCode: "",
        callingCodes: "43",
        lat: "47.5162",
        lng: "14.5501",
        SW: "46.438609, 9.777215",
        NE: "49.039824, 17.604634",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Azerbaijan",
        territoryAlternativeNames: "",
        territoryCountry: "Azerbaijan",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "AZ",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "AZE",
        state_code: "",
        skratchCode: "",
        callingCodes: "994",
        lat: "40.1431",
        lng: "47.5769",
        SW: "39.127918, 46.084924",
        NE: "41.811423, 50.134921",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Azores",
        territoryAlternativeNames: "",
        territoryCountry: "Portugal",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "PRT",
        alpha3Code: "PRTAZ",
        state_code: "",
        skratchCode: "PRTAZ",
        callingCodes: "351",
        lat: "37.782342",
        lng: "-25.452077",
        SW: "37.222787, -31.402922",
        NE: "39.465817, -24.743914",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bahamas",
        territoryAlternativeNames: "",
        territoryCountry: "Bahamas",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "BS",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BHS",
        state_code: "",
        skratchCode: "",
        callingCodes: "1242",
        lat: "25.0343",
        lng: "-77.3963",
        SW: "23.583498, -78.566296",
        NE: "25.329817, -76.961331",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bahrain",
        territoryAlternativeNames: "",
        territoryCountry: "Bahrain",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "BH",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BHR",
        state_code: "",
        skratchCode: "",
        callingCodes: "973",
        lat: "26.0667",
        lng: "50.5577",
        SW: "25.801613, 50.410438",
        NE: "26.336175, 50.783549",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bangladesh",
        territoryAlternativeNames: "",
        territoryCountry: "Bangladesh",
        territoryWRegion: "South Asia",
        territoryType: "Country",
        alpha2Code: "BD",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BGD",
        state_code: "",
        skratchCode: "",
        callingCodes: "880",
        lat: "23.685",
        lng: "90.3563",
        SW: "21.553263, 88.447021",
        NE: "26.412064, 93.015228",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Barbados",
        territoryAlternativeNames: "",
        territoryCountry: "Barbados",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "BB",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BRB",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "13.1939",
        lng: "-59.5432",
        SW: "13.037704, -59.683578",
        NE: "13.337782, -59.374271",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Belarus",
        territoryAlternativeNames: "",
        territoryCountry: "Belarus",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "BY",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BLR",
        state_code: "",
        skratchCode: "",
        callingCodes: "375",
        lat: "53.7098",
        lng: "27.9534",
        SW: "51.284594, 23.283125",
        NE: "55.699489, 33.060743",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Belgium",
        territoryAlternativeNames: "",
        territoryCountry: "Belgium",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "BE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BEL",
        state_code: "",
        skratchCode: "",
        callingCodes: "32",
        lat: "50.5039",
        lng: "4.4699",
        SW: "49.725860, 3.235376",
        NE: "51.395237, 6.329429",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Belize",
        territoryAlternativeNames: "",
        territoryCountry: "Belize",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "BZ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BLZ",
        state_code: "",
        skratchCode: "",
        callingCodes: "501",
        lat: "17.123082",
        lng: "-88.634053",
        SW: "15.837570, -89.446999",
        NE: "8.205305, -87.647626",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Benin",
        territoryAlternativeNames: "",
        territoryCountry: "Benin",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "BJ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BEN",
        state_code: "",
        skratchCode: "",
        callingCodes: "229",
        lat: "9.3077",
        lng: "2.3158",
        SW: "5.957317, 1.245764",
        NE: "12.169430, 4.152684",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bermuda",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "BMU",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "32.3078",
        lng: "-64.7505",
        SW: "32.237155, -64.884466",
        NE: "32.400972, -64.639474",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bhutan",
        territoryAlternativeNames: "",
        territoryCountry: "Bhutan",
        territoryWRegion: "South Asia",
        territoryType: "Country",
        alpha2Code: "BT",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BTN",
        state_code: "",
        skratchCode: "",
        callingCodes: "975",
        lat: "27.5142",
        lng: "90.4336",
        SW: "26.769833, 88.784962",
        NE: "27.987132, 92.112016",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bolivia",
        territoryAlternativeNames: "",
        territoryCountry: "Bolivia",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "BO",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BOL",
        state_code: "",
        skratchCode: "",
        callingCodes: "591",
        lat: "-16.2902",
        lng: "-63.5887",
        SW: "-23.065512, -68.854361",
        NE: "-10.670670, -57.703741",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bonaire",
        territoryAlternativeNames: "",
        territoryCountry: "Netherlands",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "NLD",
        alpha3Code: "BES",
        state_code: "",
        skratchCode: "",
        callingCodes: "599",
        lat: "12.2019",
        lng: "-68.2624",
        SW: "12.017734, -68.393366",
        NE: "12.309735, -68.145957",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bosnia and Herzegovina",
        territoryAlternativeNames: "",
        territoryCountry: "Bosnia and Herzegovina",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "BA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BIH",
        state_code: "",
        skratchCode: "",
        callingCodes: "387",
        lat: "43.9159",
        lng: "17.6791",
        SW: "42.789588, 16.368859",
        NE: "45.094586, 19.745231",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Botswana",
        territoryAlternativeNames: "",
        territoryCountry: "Botswana",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "BW",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BWA",
        state_code: "",
        skratchCode: "",
        callingCodes: "267",
        lat: "-22.3285",
        lng: "24.6849",
        SW: "-26.955947, 19.756424",
        NE: "-17.504761, 28.634020",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Brazil",
        territoryAlternativeNames: "",
        territoryCountry: "Brazil",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "BR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BRA",
        state_code: "",
        skratchCode: "",
        callingCodes: "55",
        lat: "-14.2350",
        lng: "-51.9253",
        SW: "-31.943440, -65.417740",
        NE: "3.923753, -31.695182",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "British Indian Ocean Territory",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "South Asia",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "IOT",
        state_code: "",
        skratchCode: "",
        callingCodes: "246",
        lat: "-6.3432",
        lng: "71.8765",
        SW: "-7.526482, 71.195498",
        NE: "-6.079380, 72.873754",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "British Virgin Islands",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "VGB",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "18.4207",
        lng: "-64.6400",
        SW: "18.351176, -64.760994",
        NE: "18.526246, -64.314274",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Brunei",
        territoryAlternativeNames: "",
        territoryCountry: "Brunei",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "BN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BRN",
        state_code: "",
        skratchCode: "",
        callingCodes: "673",
        lat: "4.5353",
        lng: "114.7277",
        SW: "3.993932, 114.153586",
        NE: "5.015223, 115.207682",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Bulgaria",
        territoryAlternativeNames: "",
        territoryCountry: "Bulgaria",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "BG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BGR",
        state_code: "",
        skratchCode: "",
        callingCodes: "359",
        lat: "42.7339",
        lng: "25.4858",
        SW: "41.246944, 22.622898",
        NE: "43.990825, 28.921025",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Burkina Faso",
        territoryAlternativeNames: "",
        territoryCountry: "Burkina Faso",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "BF",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BFA",
        state_code: "",
        skratchCode: "",
        callingCodes: "226",
        lat: "12.2383",
        lng: "-1.5616",
        SW: "9.724793, -5.469908",
        NE: "14.888738, 2.092471",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Burundi",
        territoryAlternativeNames: "",
        territoryCountry: "Burundi",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "BI",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "BDI",
        state_code: "",
        skratchCode: "",
        callingCodes: "257",
        lat: "-3.3731",
        lng: "29.9189",
        SW: "-4.524810, 29.167140",
        NE: "-2.396680, 30.939779",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "California",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USACA",
        state_code: "CA",
        skratchCode: "USACA",
        callingCodes: "",
        lat: "36.7783",
        lng: "-119.4179",
        SW: "33.197486, -123.610010",
        NE: "41.789162, -114.497197",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Cambodia",
        territoryAlternativeNames: "",
        territoryCountry: "Cambodia",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "KH",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "KHM",
        state_code: "",
        skratchCode: "",
        callingCodes: "855",
        lat: "12.5657",
        lng: "104.991",
        SW: "10.394686, 102.528261",
        NE: "14.732613, 107.870805",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Cameroon",
        territoryAlternativeNames: "",
        territoryCountry: "Cameroon",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "CM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CMR",
        state_code: "",
        skratchCode: "",
        callingCodes: "237",
        lat: "4.894290",
        lng: "12.497463",
        SW: "2.243504, 8.976906",
        NE: "11.296198, 16.898412",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Canada",
        territoryAlternativeNames: "",
        territoryCountry: "Canada",
        territoryWRegion: "North America",
        territoryType: "Country",
        alpha2Code: "CA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CAN",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "56.1304",
        lng: "-106.3468",
        SW: "46.728096, -130.275635",
        NE: "50.548659, -52.095526",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Canary Islands",
        territoryAlternativeNames: "",
        territoryCountry: "Spain",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "ESP",
        alpha3Code: "ESPCI",
        state_code: "",
        skratchCode: "ESPCI",
        callingCodes: "34",
        lat: "28.236478",
        lng: "-16.601742",
        SW: "27.450770, -18.364670",
        NE: "29.360729, -13.194463",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Cape Verde",
        territoryAlternativeNames: "",
        territoryCountry: "Cape Verde",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "CV",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CPV",
        state_code: "",
        skratchCode: "",
        callingCodes: "238",
        lat: "15.057953",
        lng: "-23.631359",
        SW: "14.756738, -25.498057",
        NE: "17.089012, -22.279241",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Cayman Islands",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "CYM",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "19.3133",
        lng: "-81.2546",
        SW: "19.216486, -81.504952",
        NE: "19.812100, -79.637008",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Central African Republic",
        territoryAlternativeNames: "",
        territoryCountry: "Central African Republic",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "CF",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CAF",
        state_code: "",
        skratchCode: "",
        callingCodes: "236",
        lat: "6.6111",
        lng: "20.9394",
        SW: "2.371044, 14.992058",
        NE: "10.954340, 26.837617",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Ceuta",
        territoryAlternativeNames: "",
        territoryCountry: "Spain",
        territoryWRegion: "Africa",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "ESP",
        alpha3Code: "ESPCA",
        state_code: "",
        skratchCode: "ESPCA",
        callingCodes: "34",
        lat: "35.8894",
        lng: "-5.3213",
        SW: "35.869997, -5.372081",
        NE: "35.915175, -5.276609",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Chad",
        territoryAlternativeNames: "",
        territoryCountry: "Chad",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "TD",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TCD",
        state_code: "",
        skratchCode: "",
        callingCodes: "235",
        lat: "15.4542",
        lng: "18.7322",
        SW: "7.625485, 13.519792",
        NE: "20.948578, 25.441655",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Chile",
        territoryAlternativeNames: "",
        territoryCountry: "Chile",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "CL",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CHL",
        state_code: "",
        skratchCode: "",
        callingCodes: "56",
        lat: "-35.6751",
        lng: "-71.5430",
        SW: "-53.410793, -81.073365",
        NE: "-20.789725, -64.116378",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "China",
        territoryAlternativeNames: "",
        territoryCountry: "China",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "CN",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "CHN",
        state_code: "",
        skratchCode: "",
        callingCodes: "86",
        lat: "35.8617",
        lng: "104.1954",
        SW: "20.814607, 83.226369",
        NE: "38.822897, 122.818236",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Christmas Island",
        territoryAlternativeNames: "",
        territoryCountry: "Australia",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "AUS",
        alpha3Code: "CXR",
        state_code: "",
        skratchCode: "",
        callingCodes: "61",
        lat: "-10.4475",
        lng: "105.6904",
        SW: "-10.582840, 105.528015",
        NE: "-10.407545, 105.740554",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Cocos (Keeling) Islands",
        territoryAlternativeNames: "",
        territoryCountry: "Australia",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "AUS",
        alpha3Code: "CCK",
        state_code: "",
        skratchCode: "",
        callingCodes: "891",
        lat: "-12.1642",
        lng: "96.8710",
        SW: "-12.219330, 96.781059",
        NE: "-12.078260, 96.943107",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Colombia",
        territoryAlternativeNames: "",
        territoryCountry: "Colombia",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "CO",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "COL",
        state_code: "",
        skratchCode: "",
        callingCodes: "57",
        lat: "4.5709",
        lng: "-74.2973",
        SW: "-2.879758, -79.554245",
        NE: "11.644785, -65.488413",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Colorado",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USACO",
        state_code: "CO",
        skratchCode: "USACO",
        callingCodes: "",
        lat: "39.5501",
        lng: "-105.7821",
        SW: "36.878189, -109.302580",
        NE: "41.274882, -101.414664",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Comoros",
        territoryAlternativeNames: "",
        territoryCountry: "Comoros",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "KM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "COM",
        state_code: "",
        skratchCode: "",
        callingCodes: "269",
        lat: "-11.6455",
        lng: "43.3333",
        SW: "-12.448142, 43.033929",
        NE: "-11.337153, 44.539580",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Connecticut",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USACT",
        state_code: "CT",
        skratchCode: "USACT",
        callingCodes: "",
        lat: "41.6032",
        lng: "-73.0877",
        SW: "40.943090, -73.691245",
        NE: "42.094123, -71.675295",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Cook Islands",
        territoryAlternativeNames: "",
        territoryCountry: "Cook Islands",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "CK",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "COK",
        state_code: "",
        skratchCode: "",
        callingCodes: "682",
        lat: "-21.2367",
        lng: "-159.7777",
        SW: "-21.951840, -160.142633",
        NE: "-19.747400, -157.543543",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Costa Rica",
        territoryAlternativeNames: "",
        territoryCountry: "Costa Rica",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "CR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CRI",
        state_code: "",
        skratchCode: "",
        callingCodes: "506",
        lat: "9.7489",
        lng: "-83.7534",
        SW: "8.118082, -85.567962",
        NE: "11.007080, -82.693486",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Côte d'Ivoire",
        territoryAlternativeNames: "Ivory Coast",
        territoryCountry: "Ivory Coast",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "CI",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CIV",
        state_code: "",
        skratchCode: "",
        callingCodes: "225",
        lat: "7.54",
        lng: "-5.5471",
        SW: "4.193016, -7.989471",
        NE: "10.408573, -2.155994",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Croatia",
        territoryAlternativeNames: "",
        territoryCountry: "Croatia",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "HR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "HRV",
        state_code: "",
        skratchCode: "",
        callingCodes: "385",
        lat: "45.1000",
        lng: "15.2000",
        SW: "42.798940, 14.045498",
        NE: "46.093909, 19.162783",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Cuba",
        territoryAlternativeNames: "",
        territoryCountry: "Cuba",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "CU",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CUB",
        state_code: "",
        skratchCode: "",
        callingCodes: "53",
        lat: "21.5218",
        lng: "-77.7812",
        SW: "20.593120, -85.043050",
        NE: "21.491717, -73.746459",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Curaçao",
        territoryAlternativeNames: "",
        territoryCountry: "Netherlands",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "NLD",
        alpha3Code: "CUW",
        state_code: "",
        skratchCode: "",
        callingCodes: "599",
        lat: "12.1696",
        lng: "-68.9900",
        SW: "12.014860, -69.187124",
        NE: "12.360770, -68.690733",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Cyprus",
        territoryAlternativeNames: "",
        territoryCountry: "Cyprus",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "CY",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "CYP",
        state_code: "",
        skratchCode: "",
        callingCodes: "357",
        lat: "34.908579",
        lng: "32.862677",
        SW: "34.584391, 32.324396",
        NE: "35.751530, 34.632576",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Czechia",
        territoryAlternativeNames: "",
        territoryCountry: "Czech Republic",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "CZ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CZE",
        state_code: "",
        skratchCode: "",
        callingCodes: "420",
        lat: "49.8175",
        lng: "15.473",
        SW: "48.537889, 12.567345",
        NE: "50.697444, 18.847842",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Delaware",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USADE",
        state_code: "DE",
        skratchCode: "USADE",
        callingCodes: "",
        lat: "38.9108",
        lng: "-75.5277",
        SW: "38.440350, -75.772943",
        NE: "39.772796, -75.223073",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Democratic Republic of the Congo",
        territoryAlternativeNames: "",
        territoryCountry: "Democratic Republic of the Congo",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "CD",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "COD",
        state_code: "",
        skratchCode: "",
        callingCodes: "243",
        lat: "-4.0383",
        lng: "21.7587",
        SW: "-11.395176, 13.083318",
        NE: "5.480126, 32.946701",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Denmark",
        territoryAlternativeNames: "",
        territoryCountry: "Denmark",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "DK",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "DNK",
        state_code: "",
        skratchCode: "",
        callingCodes: "45",
        lat: "56.2639",
        lng: "9.5018",
        SW: "54.758163, 7.932372",
        NE: "57.534517, 13.436658",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Djibouti",
        territoryAlternativeNames: "",
        territoryCountry: "Djibouti",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "DJ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "DJI",
        state_code: "",
        skratchCode: "",
        callingCodes: "253",
        lat: "11.8251",
        lng: "42.5903",
        SW: "10.911022, 41.707860",
        NE: "12.582402, 43.641975",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Dominica",
        territoryAlternativeNames: "",
        territoryCountry: "Dominica",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "DM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "DMA",
        state_code: "",
        skratchCode: "",
        callingCodes: "1767",
        lat: "15.414351",
        lng: "-61.330042",
        SW: "15.203105, -61.442919",
        NE: "15.642212, -61.196989",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Dominican Republic",
        territoryAlternativeNames: "",
        territoryCountry: "Dominican Republic",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "DO",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "DOM",
        state_code: "",
        skratchCode: "",
        callingCodes: "1809",
        lat: "18.7357",
        lng: "-70.1627",
        SW: "17.466872, -71.983278",
        NE: "19.805261, -67.944647",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Ecuador",
        territoryAlternativeNames: "",
        territoryCountry: "Ecuador",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "EC",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "ECU",
        state_code: "",
        skratchCode: "",
        callingCodes: "593",
        lat: "-1.8312",
        lng: "-78.1834",
        SW: "-4.488262, -81.792575",
        NE: "0.438672, -74.603906",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Egypt",
        territoryAlternativeNames: "",
        territoryCountry: "Egypt",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "EG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "EGY",
        state_code: "",
        skratchCode: "",
        callingCodes: "20",
        lat: "26.8206",
        lng: "30.8025",
        SW: "21.575151, 24.390610",
        NE: "31.208303, 34.107578",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "El Salvador",
        territoryAlternativeNames: "",
        territoryCountry: "El Salvador",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "SV",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SLV",
        state_code: "",
        skratchCode: "",
        callingCodes: "503",
        lat: "13.7942",
        lng: "-88.8965",
        SW: "13.424369, -90.185102",
        NE: "13.990332, -87.628118",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "England",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "EN",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "ENG",
        state_code: "",
        skratchCode: "",
        callingCodes: "",
        lat: "52.3555",
        lng: "-1.1743",
        SW: "49.749731, -5.747206",
        NE: "55.967651, 0.690899",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Equatorial Guinea",
        territoryAlternativeNames: "",
        territoryCountry: "Equatorial Guinea",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "GQ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GNQ",
        state_code: "",
        skratchCode: "",
        callingCodes: "240",
        lat: "1.6508",
        lng: "10.2679",
        SW: "0.877388, 9.144725",
        NE: "2.255940, 11.561466",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Eritrea",
        territoryAlternativeNames: "",
        territoryCountry: "Eritrea",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "ER",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ERI",
        state_code: "",
        skratchCode: "",
        callingCodes: "291",
        lat: "15.1794",
        lng: "39.7823",
        SW: "13.334806, 36.168949",
        NE: "17.812460, 43.435822",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Estonia",
        territoryAlternativeNames: "",
        territoryCountry: "Estonia",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "EE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "EST",
        state_code: "",
        skratchCode: "",
        callingCodes: "372",
        lat: "58.5953",
        lng: "25.0136",
        SW: "57.736240, 21.498988",
        NE: "59.579921, 29.346522",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Eswatini",
        territoryAlternativeNames: "Swaziland",
        territoryCountry: "Eswatini",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "SZ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SWZ",
        state_code: "",
        skratchCode: "",
        callingCodes: "268",
        lat: "-26.5225",
        lng: "31.4659",
        SW: "-27.323227, 30.730661",
        NE: "-25.763559, 32.270109",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Ethiopia",
        territoryAlternativeNames: "",
        territoryCountry: "Ethiopia",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "ET",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ETH",
        state_code: "",
        skratchCode: "",
        callingCodes: "251",
        lat: "9.145",
        lng: "40.4897",
        SW: "3.767749, 33.663451",
        NE: "14.164655, 47.818210",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Falkland Islands",
        territoryAlternativeNames: "Islas Malvinas",
        territoryCountry: "United Kingdom",
        territoryWRegion: "South America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "FLK",
        state_code: "",
        skratchCode: "",
        callingCodes: "500",
        lat: "-51.7963",
        lng: "-59.5236",
        SW: "-52.435028, -61.403025",
        NE: "-51.273508, -57.498441",
        "Flag?": "No",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Faroe Islands",
        territoryAlternativeNames: "",
        territoryCountry: "Denmark",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "DNK",
        alpha3Code: "FRO",
        state_code: "",
        skratchCode: "",
        callingCodes: "298",
        lat: "61.8926",
        lng: "-6.9118",
        SW: "61.417974, -7.664067",
        NE: "62.399902, -5.971952",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Fiji",
        territoryAlternativeNames: "",
        territoryCountry: "Fiji",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "FJ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "FJI",
        state_code: "",
        skratchCode: "",
        callingCodes: "679",
        lat: "-17.885362",
        lng: "177.957769",
        SW: "-19.390378, 176.754125",
        NE: "-16.090870, -178.631580",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Finland",
        territoryAlternativeNames: "",
        territoryCountry: "Finland",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "FI",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "FIN",
        state_code: "",
        skratchCode: "",
        callingCodes: "358",
        lat: "61.9241",
        lng: "25.7482",
        SW: "59.849790, 20.399153",
        NE: "69.557656, 31.588389",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Florida",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAFL",
        state_code: "FL",
        skratchCode: "USAFL",
        callingCodes: "",
        lat: "27.6648",
        lng: "-81.5158",
        SW: "25.280050, -83.843169",
        NE: "30.516034, -79.777740",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "France",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "FR",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "FRA",
        state_code: "",
        skratchCode: "",
        callingCodes: "33",
        lat: "46.2276",
        lng: "2.2137",
        SW: "42.953931, -1.645126",
        NE: "50.572198, 9.168338",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "French Guiana",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "South America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "GUF",
        state_code: "",
        skratchCode: "",
        callingCodes: "594",
        lat: "3.9339",
        lng: "-53.1258",
        SW: "2.141649, -54.814305",
        NE: "5.504822, -50.953745",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "French Polynesia",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "PYF",
        state_code: "",
        skratchCode: "",
        callingCodes: "689",
        lat: "-17.6797",
        lng: "-149.4068",
        SW: "-17.936333, -151.790710",
        NE: "-16.528647, -148.692700",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Gabon",
        territoryAlternativeNames: "",
        territoryCountry: "Gabon",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "GA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GAB",
        state_code: "",
        skratchCode: "",
        callingCodes: "241",
        lat: "-0.8037",
        lng: "11.6094",
        SW: "-3.897175, 8.655273",
        NE: "2.177426, 14.643347",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Galapagos Islands",
        territoryAlternativeNames: "",
        territoryCountry: "Ecuador",
        territoryWRegion: "South America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "ECU",
        alpha3Code: "ECUGI",
        state_code: "",
        skratchCode: "ECUGI",
        callingCodes: "593",
        lat: "-0.9538",
        lng: "-90.9656",
        SW: "-1.205478, -91.727029",
        NE: "0.121466, -89.262123",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Gambia",
        territoryAlternativeNames: "",
        territoryCountry: "Gambia",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "GM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GMB",
        state_code: "",
        skratchCode: "",
        callingCodes: "220",
        lat: "13.4432",
        lng: "-15.3101",
        SW: "13.013806, -16.929115",
        NE: "13.897468, -13.586970",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Georgia",
        territoryAlternativeNames: "",
        territoryCountry: "Georgia",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "GE",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "GEO",
        state_code: "",
        skratchCode: "USAGA",
        callingCodes: "995",
        lat: "42.3154",
        lng: "43.3569",
        SW: "41.378093, 40.399286",
        NE: "42.673511, 46.986337",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Georgia",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "GO",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAGA",
        state_code: "GA",
        skratchCode: "",
        callingCodes: "",
        lat: "32.1656",
        lng: "-82.9001",
        SW: "30.443771, -85.314801",
        NE: "34.920230, -81.085774",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Germany",
        territoryAlternativeNames: "",
        territoryCountry: "Germany",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "DE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "DEU",
        state_code: "",
        skratchCode: "",
        callingCodes: "49",
        lat: "51.1657",
        lng: "10.4515",
        SW: "47.474310, 6.622245",
        NE: "54.553364, 15.248068",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Ghana",
        territoryAlternativeNames: "",
        territoryCountry: "Ghana",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "GH",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GHA",
        state_code: "",
        skratchCode: "",
        callingCodes: "233",
        lat: "7.9465",
        lng: "-1.0232",
        SW: "4.645053, -3.417502",
        NE: "11.167048, 0.973997",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Gibraltar",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "GIB",
        state_code: "",
        skratchCode: "",
        callingCodes: "350",
        lat: "36.1408",
        lng: "-5.3536",
        SW: "36.107457, -5.366353",
        NE: "36.155697, -5.331821",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Greece",
        territoryAlternativeNames: "",
        territoryCountry: "Greece",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "GR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GRC",
        state_code: "",
        skratchCode: "",
        callingCodes: "30",
        lat: "39.0742",
        lng: "21.8243",
        SW: "35.233928, 20.625675",
        NE: "40.937325, 27.714731",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Greenland",
        territoryAlternativeNames: "",
        territoryCountry: "Denmark",
        territoryWRegion: "North America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "DNK",
        alpha3Code: "GRL",
        state_code: "",
        skratchCode: "",
        callingCodes: "299",
        lat: "61.741432",
        lng: "-45.356283",
        SW: "60.068111, -53.944756",
        NE: "67.708084, -31.765005",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Grenada",
        territoryAlternativeNames: "",
        territoryCountry: "Grenada",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "GD",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GRD",
        state_code: "",
        skratchCode: "",
        callingCodes: "1473",
        lat: "12.1165",
        lng: "-61.6790",
        SW: "11.975112, -61.841686",
        NE: "12.541186, -61.353220",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Guadeloupe",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "GLP",
        state_code: "",
        skratchCode: "",
        callingCodes: "590",
        lat: "6.256272",
        lng: "-61.571443",
        SW: "15.851186, -61.850067",
        NE: "16.494476, -61.045137",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Guam",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "GUM",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "13.4443",
        lng: "144.7937",
        SW: "13.208872, 144.566247",
        NE: "13.673325, 145.021661",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Guatemala",
        territoryAlternativeNames: "",
        territoryCountry: "Guatemala",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "GT",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GTM",
        state_code: "",
        skratchCode: "",
        callingCodes: "502",
        lat: "15.7835",
        lng: "-90.2308",
        SW: "13.996522, -92.577597",
        NE: "17.059590, -88.037975",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Guernsey",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "GU",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "GUE",
        state_code: "",
        skratchCode: "",
        callingCodes: "44",
        lat: "49.4482",
        lng: "-2.5895",
        SW: "49.404135, -2.697146",
        NE: "49.735088, -2.134577",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Guinea",
        territoryAlternativeNames: "",
        territoryCountry: "Guinea",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "GN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GIN",
        state_code: "",
        skratchCode: "",
        callingCodes: "224",
        lat: "9.9456",
        lng: "-9.6966",
        SW: "8.460968, -14.876475",
        NE: "12.180374, -7.767734",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Guinea-Bissau",
        territoryAlternativeNames: "",
        territoryCountry: "Guinea-Bissau",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "GW",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GNB",
        state_code: "",
        skratchCode: "",
        callingCodes: "245",
        lat: "11.8037",
        lng: "-15.1804",
        SW: "10.783723, -16.448977",
        NE: "12.748795, -13.511468",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Guyana",
        territoryAlternativeNames: "",
        territoryCountry: "Guyana",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "GY",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "GUY",
        state_code: "",
        skratchCode: "",
        callingCodes: "592",
        lat: "4.8604",
        lng: "-58.9302",
        SW: "1.078762, -60.838545",
        NE: "7.186088, -56.708670",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Haiti",
        territoryAlternativeNames: "",
        territoryCountry: "Haiti",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "HT",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "HTI",
        state_code: "",
        skratchCode: "",
        callingCodes: "509",
        lat: "18.9712",
        lng: "-72.2852",
        SW: "17.961073, -74.542261",
        NE: "19.990932, -71.410551",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Hawaii",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAHI",
        state_code: "HI",
        skratchCode: "USAHI",
        callingCodes: "",
        lat: "19.8968",
        lng: "-155.5828",
        SW: "18.868484, -160.272044",
        NE: "22.072886, -154.278591",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Honduras",
        territoryAlternativeNames: "",
        territoryCountry: "Honduras",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "HN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "HND",
        state_code: "",
        skratchCode: "",
        callingCodes: "504",
        lat: "15.2000",
        lng: "-86.2419",
        SW: "13.226443, -89.282597",
        NE: "15.946607, -83.410269",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Hong Kong",
        territoryAlternativeNames: "",
        territoryCountry: "China",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "CHN",
        alpha3Code: "HKG",
        state_code: "",
        skratchCode: "",
        callingCodes: "852",
        lat: "22.3193",
        lng: "114.1694",
        SW: "22.168604, 113.820745",
        NE: "22.559815, 114.412408",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Hungary",
        territoryAlternativeNames: "",
        territoryCountry: "Hungary",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "HU",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "HUN",
        state_code: "",
        skratchCode: "",
        callingCodes: "36",
        lat: "47.1625",
        lng: "19.5033",
        SW: "45.925742, 16.573862",
        NE: "48.402806, 23.059270",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Iceland",
        territoryAlternativeNames: "",
        territoryCountry: "Iceland",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "IS",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ISL",
        state_code: "",
        skratchCode: "",
        callingCodes: "354",
        lat: "64.9631",
        lng: "-19.0208",
        SW: "63.157993, -23.605302",
        NE: "66.577957, -12.908040",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Idaho",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAID",
        state_code: "ID",
        skratchCode: "USAID",
        callingCodes: "",
        lat: "44.0682",
        lng: "-114.7420",
        SW: "41.781132, -117.616281",
        NE: "48.708188, -110.960869",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Illinois",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAIL",
        state_code: "IL",
        skratchCode: "USAIL",
        callingCodes: "",
        lat: "40.6331",
        lng: "-89.3985",
        SW: "37.380607, -91.488743",
        NE: "42.451038, -87.046132",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "India",
        territoryAlternativeNames: "",
        territoryCountry: "India",
        territoryWRegion: "South Asia",
        territoryType: "Country",
        alpha2Code: "IN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "IND",
        state_code: "",
        skratchCode: "",
        callingCodes: "91",
        lat: "20.5937",
        lng: "78.9629",
        SW: "8.162033, 71.540839",
        NE: "26.296128, 89.390899",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Indiana",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAIN",
        state_code: "IN",
        skratchCode: "USAIN",
        callingCodes: "",
        lat: "40.2672",
        lng: "-86.1349",
        SW: "37.730652, -88.267718",
        NE: "41.895984, -84.484860",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Indonesia",
        territoryAlternativeNames: "",
        territoryCountry: "Indonesia",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "ID",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "IDN",
        state_code: "",
        skratchCode: "",
        callingCodes: "62",
        lat: "-0.7893",
        lng: "113.9213",
        SW: "-10.530751, 94.058071",
        NE: "1.707377, 139.230271",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Iowa",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAIA",
        state_code: "IA",
        skratchCode: "USAIA",
        callingCodes: "",
        lat: "41.8780",
        lng: "-93.0977",
        SW: "40.457875, -96.332130",
        NE: "43.621428, -90.469476",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Iran",
        territoryAlternativeNames: "",
        territoryCountry: "Iran",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "IR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "IRN",
        state_code: "",
        skratchCode: "",
        callingCodes: "98",
        lat: "32.4279",
        lng: "53.6880",
        SW: "27.589503, 46.396670",
        NE: "36.744565, 63.292424",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Iraq",
        territoryAlternativeNames: "",
        territoryCountry: "Iraq",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "IQ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "IRQ",
        state_code: "",
        skratchCode: "",
        callingCodes: "964",
        lat: "33.2232",
        lng: "43.6793",
        SW: "30.228468, 39.263520",
        NE: "36.278358, 48.625132",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Ireland",
        territoryAlternativeNames: "",
        territoryCountry: "Ireland",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "IE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "IRL",
        state_code: "",
        skratchCode: "",
        callingCodes: "353",
        lat: "53.046624",
        lng: "-7.927075",
        SW: "51.299692, -10.565440",
        NE: "55.118301, -4.906146",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Israel",
        territoryAlternativeNames: "",
        territoryCountry: "Israel",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "IL",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ISR",
        state_code: "",
        skratchCode: "",
        callingCodes: "972",
        lat: "31.0461",
        lng: "34.8516",
        SW: "29.376592, 34.043704",
        NE: "33.247333, 36.919675",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Italy",
        territoryAlternativeNames: "",
        territoryCountry: "Italy",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "IT",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ITA",
        state_code: "",
        skratchCode: "",
        callingCodes: "39",
        lat: "41.8719",
        lng: "12.5674",
        SW: "37.719864, 7.377836",
        NE: "46.272278, 17.486294",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Jamaica",
        territoryAlternativeNames: "",
        territoryCountry: "Jamaica",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "JM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "JAM",
        state_code: "",
        skratchCode: "",
        callingCodes: "1876",
        lat: "18.1096",
        lng: "-77.2975",
        SW: "17.681520, -78.406792",
        NE: "18.456061, -76.061277",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Japan",
        territoryAlternativeNames: "",
        territoryCountry: "Japan",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "JP",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "JPN",
        state_code: "",
        skratchCode: "",
        callingCodes: "81",
        lat: "36.2048",
        lng: "138.2529",
        SW: "29.905106, 128.586160",
        NE: "44.570092, 147.118610",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Jersey",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "JE",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "JER",
        state_code: "",
        skratchCode: "",
        callingCodes: "44",
        lat: "49.2144",
        lng: "-2.1312",
        SW: "49.161625, -2.259784",
        NE: "49.247149, -1.999071",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Jordan",
        territoryAlternativeNames: "",
        territoryCountry: "Jordan",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "JO",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "JOR",
        state_code: "",
        skratchCode: "",
        callingCodes: "962",
        lat: "30.5852",
        lng: "36.2384",
        SW: "29.200649, 34.727808",
        NE: "33.280000, 39.389937",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Kansas",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAKS",
        state_code: "",
        skratchCode: "",
        callingCodes: "",
        lat: "39.0119",
        lng: "-98.4842",
        SW: "36.728939, -102.509701",
        NE: "40.073506, -94.086548",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Kazakhstan",
        territoryAlternativeNames: "",
        territoryCountry: "Kazakhstan",
        territoryWRegion: "Central Asia",
        territoryType: "Country",
        alpha2Code: "KZ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "KAZ",
        state_code: "",
        skratchCode: "",
        callingCodes: "76",
        lat: "48.0196",
        lng: "66.9237",
        SW: "40.911361, 49.854362",
        NE: "51.012206, 91.059976",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Kentucky",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAKY",
        state_code: "",
        skratchCode: "",
        callingCodes: "",
        lat: "37.8393",
        lng: "-84.2700",
        SW: "36.303844, -89.597399",
        NE: "39.092993, -81.971190",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Kenya",
        territoryAlternativeNames: "",
        territoryCountry: "Kenya",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "KE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "KEN",
        state_code: "",
        skratchCode: "",
        callingCodes: "254",
        lat: "-0.0236",
        lng: "37.9062",
        SW: "-4.056041, 33.828674",
        NE: "4.093175, 42.616897",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Kiribati",
        territoryAlternativeNames: "",
        territoryCountry: "Kiribati",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "KI",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "KIR",
        state_code: "",
        skratchCode: "",
        callingCodes: "686",
        lat: "1.896446",
        lng: "-157.395261",
        SW: "1.597309, -157.802928",
        NE: "2.162128, -157.020335",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Kosovo",
        territoryAlternativeNames: "",
        territoryCountry: "Serbia",
        territoryWRegion: "Europe",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "SRB",
        alpha3Code: "SRBKV",
        state_code: "",
        skratchCode: "SRBKV",
        callingCodes: "383",
        lat: "42.6026",
        lng: "20.903",
        SW: "41.933333, 20.134549",
        NE: "43.162080, 22.127764",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Kuwait",
        territoryAlternativeNames: "",
        territoryCountry: "Kuwait",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "KW",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "KWT",
        state_code: "",
        skratchCode: "",
        callingCodes: "965",
        lat: "29.3117",
        lng: "47.4818",
        SW: "28.558651, 46.709865",
        NE: "29.918988, 48.709935",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Kyrgyzstan",
        territoryAlternativeNames: "",
        territoryCountry: "Kyrgyzstan",
        territoryWRegion: "Central Asia",
        territoryType: "Country",
        alpha2Code: "KG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "KGZ",
        state_code: "",
        skratchCode: "",
        callingCodes: "996",
        lat: "41.2044",
        lng: "74.7661",
        SW: "39.176665, 69.244834",
        NE: "43.066480, 80.746902",
        "Flag?": "No",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Laos",
        territoryAlternativeNames: "",
        territoryCountry: "Laos",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "LA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LAO",
        state_code: "",
        skratchCode: "",
        callingCodes: "856",
        lat: "19.8563",
        lng: "102.4955",
        SW: "14.586525, 101.416058",
        NE: "22.642705, 107.292002",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Latvia",
        territoryAlternativeNames: "",
        territoryCountry: "Latvia",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "LV",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LVA",
        state_code: "",
        skratchCode: "",
        callingCodes: "371",
        lat: "56.8796",
        lng: "24.6032",
        SW: "55.953639, 20.669648",
        NE: "57.702918, 28.449799",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Lebanon",
        territoryAlternativeNames: "",
        territoryCountry: "Lebanon",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "LB",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LBN",
        state_code: "",
        skratchCode: "",
        callingCodes: "961",
        lat: "33.8547",
        lng: "35.8623",
        SW: "32.946551, 34.872967",
        NE: "34.704266, 36.917852",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Lesotho",
        territoryAlternativeNames: "",
        territoryCountry: "Lesotho",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "LS",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LSO",
        state_code: "",
        skratchCode: "",
        callingCodes: "266",
        lat: "-29.6100",
        lng: "28.2336",
        SW: "-30.779896, 27.023209",
        NE: "-28.419881, 29.850436",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Liberia",
        territoryAlternativeNames: "",
        territoryCountry: "Liberia",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "LR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LBR",
        state_code: "",
        skratchCode: "",
        callingCodes: "231",
        lat: "6.4281",
        lng: "-9.4295",
        SW: "4.712238, -11.631445",
        NE: "7.777054, -6.574857",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Libya",
        territoryAlternativeNames: "",
        territoryCountry: "Libya",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "LY",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LBY",
        state_code: "",
        skratchCode: "",
        callingCodes: "218",
        lat: "26.3351",
        lng: "17.2283",
        SW: "22.588735, 9.973367",
        NE: "32.306096, 26.159698",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Liechtenstein",
        territoryAlternativeNames: "",
        territoryCountry: "Liechtenstein",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "LI",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LIE",
        state_code: "",
        skratchCode: "",
        callingCodes: "423",
        lat: "47.1660",
        lng: "9.5554",
        SW: "47.039797, 9.430680",
        NE: "47.258528, 9.665392",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Lithuania",
        territoryAlternativeNames: "",
        territoryCountry: "Lithuania",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "LT",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LTU",
        state_code: "",
        skratchCode: "",
        callingCodes: "370",
        lat: "55.1694",
        lng: "23.8813",
        SW: "53.945066, 20.989696",
        NE: "56.312693, 27.003404",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Louisiana",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USALA",
        state_code: "LA",
        skratchCode: "USALA",
        callingCodes: "",
        lat: "30.9843",
        lng: "-91.9623",
        SW: "29.152733, -94.098604",
        NE: "32.878994, -89.457479",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Luxembourg",
        territoryAlternativeNames: "",
        territoryCountry: "Luxembourg",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "LU",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LUX",
        state_code: "",
        skratchCode: "",
        callingCodes: "352",
        lat: "49.8153",
        lng: "6.1296",
        SW: "49.441555, 5.744647",
        NE: "50.150546, 6.533419",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Macau",
        territoryAlternativeNames: "",
        territoryCountry: "China",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "CHN",
        alpha3Code: "MAC",
        state_code: "",
        skratchCode: "",
        callingCodes: "853",
        lat: "22.1987",
        lng: "113.5439",
        SW: "22.108015, 113.530639",
        NE: "22.217250, 113.584855",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Madagascar",
        territoryAlternativeNames: "",
        territoryCountry: "Madagascar",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "MG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MDG",
        state_code: "",
        skratchCode: "",
        callingCodes: "261",
        lat: "-18.7669",
        lng: "46.8691",
        SW: "-25.859287, 42.300566",
        NE: "-12.186312, 52.356553",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Madeira",
        territoryAlternativeNames: "",
        territoryCountry: "Portugal",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "PRT",
        alpha3Code: "PRTMD",
        state_code: "",
        skratchCode: "PRTMD",
        callingCodes: "351",
        lat: "32.7607",
        lng: "-16.9595",
        SW: "32.634320, -17.275857",
        NE: "32.827257, -16.695216",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Maine",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAME",
        state_code: "ME",
        skratchCode: "USAME",
        callingCodes: "",
        lat: "45.2538",
        lng: "-69.4455",
        SW: "43.218754, -71.568691",
        NE: "47.277836, -66.980346",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Malawi",
        territoryAlternativeNames: "",
        territoryCountry: "Malawi",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "MW",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MWI",
        state_code: "",
        skratchCode: "",
        callingCodes: "265",
        lat: "-13.2543",
        lng: "34.3015",
        SW: "-16.861105, 32.982835",
        NE: "-9.589088, 35.634770",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Malaysia",
        territoryAlternativeNames: "",
        territoryCountry: "Malaysia",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "MY",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MYS",
        state_code: "",
        skratchCode: "",
        callingCodes: "60",
        lat: "4.2105",
        lng: "101.9758",
        SW: "0.456235, 100.335731",
        NE: "7.399832, 119.527013",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Maldives",
        territoryAlternativeNames: "",
        territoryCountry: "Maldives",
        territoryWRegion: "South Asia",
        territoryType: "Country",
        alpha2Code: "MV",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MDV",
        state_code: "",
        skratchCode: "",
        callingCodes: "960",
        lat: "4.174230",
        lng: "73.509688",
        SW: "-0.787744, 72.785035",
        NE: "0.764466, 73.798881",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Mali",
        territoryAlternativeNames: "",
        territoryCountry: "Mali",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "ML",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MLI",
        state_code: "",
        skratchCode: "",
        callingCodes: "223",
        lat: "17.5707",
        lng: "-3.9962",
        SW: "10.748130, -11.998848",
        NE: "23.590158, 7.310092",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Malta",
        territoryAlternativeNames: "",
        territoryCountry: "Malta",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "MT",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MLT",
        state_code: "",
        skratchCode: "",
        callingCodes: "356",
        lat: "35.9375",
        lng: "14.3754",
        SW: "35.819470, 14.153930",
        NE: "36.058136, 14.574675",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Marshall Islands",
        territoryAlternativeNames: "",
        territoryCountry: "Marshall Islands",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "MH",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MHL",
        state_code: "",
        skratchCode: "",
        callingCodes: "692",
        lat: "7.1315",
        lng: "171.1845",
        SW: "5.824413, 166.923009",
        NE: "9.580969, 171.451259",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Martinique",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "MTQ",
        state_code: "",
        skratchCode: "",
        callingCodes: "596",
        lat: "14.6415",
        lng: "-61.0242",
        SW: "14.429267, -61.226738",
        NE: "14.868722, -60.796002",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Maryland",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAMD",
        state_code: "MD",
        skratchCode: "USAMD",
        callingCodes: "",
        lat: "39.0458",
        lng: "-76.6413",
        SW: "38.354151, -79.341363",
        NE: "39.710417, -75.130829",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Massachusetts",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAMA",
        state_code: "MA",
        skratchCode: "USAMA",
        callingCodes: "",
        lat: "42.4072",
        lng: "-71.3824",
        SW: "41.588069, -73.574147",
        NE: "42.792991, -69.868276",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Mauritania",
        territoryAlternativeNames: "",
        territoryCountry: "Mauritania",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "MR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MRT",
        state_code: "",
        skratchCode: "",
        callingCodes: "222",
        lat: "21.0079",
        lng: "-10.9408",
        SW: "15.516677, -17.587536",
        NE: "25.939334, -3.448990",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Mauritius",
        territoryAlternativeNames: "",
        territoryCountry: "Mauritius",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "MU",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MUS",
        state_code: "",
        skratchCode: "",
        callingCodes: "230",
        lat: "-20.3484",
        lng: "57.5522",
        SW: "-20.614447, 57.204301",
        NE: "-19.815243, 58.002522",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Mayotte",
        territoryAlternativeNames: "",
        territoryCountry: "Mayotte",
        territoryWRegion: "Africa",
        territoryType: "Other Territory",
        alpha2Code: "YT",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "MYT",
        state_code: "",
        skratchCode: "",
        callingCodes: "262",
        lat: "-12.8275",
        lng: "45.1662",
        SW: "-13.031381, 44.991061",
        NE: "-12.643934, 45.313580",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Melilla",
        territoryAlternativeNames: "",
        territoryCountry: "Spain",
        territoryWRegion: "Africa",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "ESP",
        alpha3Code: "ESPME",
        state_code: "",
        skratchCode: "ESPME",
        callingCodes: "34",
        lat: "35.2923",
        lng: "-2.9381",
        SW: "35.265703, -2.971446",
        NE: "35.318788, -2.900161",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Mexico",
        territoryAlternativeNames: "",
        territoryCountry: "Mexico",
        territoryWRegion: "North America",
        territoryType: "Country",
        alpha2Code: "MX",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MEX",
        state_code: "",
        skratchCode: "",
        callingCodes: "52",
        lat: "23.6345",
        lng: "-102.5528",
        SW: "15.515269, -113.544621",
        NE: "29.441581, -93.002488",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Michigan",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAMI",
        state_code: "MI",
        skratchCode: "USAMI",
        callingCodes: "",
        lat: "44.3148",
        lng: "-85.6024",
        SW: "41.581859, -89.425673",
        NE: "47.079488, -80.674254",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Micronesia",
        territoryAlternativeNames: "",
        territoryCountry: "Micronesia",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "FM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "FSM",
        state_code: "",
        skratchCode: "",
        callingCodes: "691",
        lat: "7.4256",
        lng: "150.5508",
        SW: "4.913470, 150.033807",
        NE: "8.929416, 163.274170",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Minnesota",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAMN",
        state_code: "MN",
        skratchCode: "USAMN",
        callingCodes: "",
        lat: "46.7296",
        lng: "-94.6859",
        SW: "43.114251, -97.225645",
        NE: "48.340399, -88.684164",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Mississippi",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAMS",
        state_code: "MS",
        skratchCode: "USAMS",
        callingCodes: "",
        lat: "32.3547",
        lng: "-89.3985",
        SW: "30.249324, -91.637216",
        NE: "35.061987, -87.550896",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Missouri",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAMO",
        state_code: "MO",
        skratchCode: "USAMO",
        callingCodes: "",
        lat: "37.9643",
        lng: "-91.8318",
        SW: "36.286968, -95.216358",
        NE: "40.586214, -89.946143",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Moldova",
        territoryAlternativeNames: "",
        territoryCountry: "Moldova",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "MD",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "MDA",
        state_code: "",
        skratchCode: "",
        callingCodes: "373",
        lat: "47.4116",
        lng: "28.3699",
        SW: "45.333041, 26.990504",
        NE: "48.396094, 30.344496",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Monaco",
        territoryAlternativeNames: "",
        territoryCountry: "Monaco",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "MC",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MCO",
        state_code: "",
        skratchCode: "",
        callingCodes: "377",
        lat: "43.7384",
        lng: "7.4246",
        SW: "43.723339, 7.407485",
        NE: "43.751798, 7.445518",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Mongolia",
        territoryAlternativeNames: "",
        territoryCountry: "Mongolia",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "MN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MNG",
        state_code: "",
        skratchCode: "",
        callingCodes: "976",
        lat: "46.8625",
        lng: "103.8467",
        SW: "42.229023, 89.687184",
        NE: "50.273620, 120.945730",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Montana",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAMT",
        state_code: "MT",
        skratchCode: "USAMT",
        callingCodes: "",
        lat: "46.8797",
        lng: "-110.3626",
        SW: "44.605818, -115.700824",
        NE: "49.461805, -102.667743",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Montenegro",
        territoryAlternativeNames: "",
        territoryCountry: "Montenegro",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "ME",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MNE",
        state_code: "",
        skratchCode: "",
        callingCodes: "382",
        lat: "42.7087",
        lng: "19.3744",
        SW: "41.848412, 18.142039",
        NE: "43.429550, 20.848895",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Montserrat",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "MSR",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "16.7425",
        lng: "-62.1874",
        SW: "16.665673, -62.249945",
        NE: "16.828383, -62.122136",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Morocco",
        territoryAlternativeNames: "",
        territoryCountry: "Morocco",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "MA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MAR",
        state_code: "",
        skratchCode: "",
        callingCodes: "212",
        lat: "31.7917",
        lng: "-7.0926",
        SW: "27.432530, -13.313393",
        NE: "35.571495, -1.081486",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Mozambique",
        territoryAlternativeNames: "",
        territoryCountry: "Mozambique",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "MZ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MOZ",
        state_code: "",
        skratchCode: "",
        callingCodes: "258",
        lat: "-18.6657",
        lng: "35.5296",
        SW: "-27.191170, 30.308519",
        NE: "-10.275506, 42.617641",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Myanmar",
        territoryAlternativeNames: "",
        territoryCountry: "Myanmar",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "MM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MMR",
        state_code: "",
        skratchCode: "",
        callingCodes: "95",
        lat: "21.9162",
        lng: "95.9560",
        SW: "10.363820, 93.433824",
        NE: "27.710521, 103.190250",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Namibia",
        territoryAlternativeNames: "",
        territoryCountry: "Namibia",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "NA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "NAM",
        state_code: "",
        skratchCode: "",
        callingCodes: "264",
        lat: "-22.9576",
        lng: "18.4904",
        SW: "-28.996080, 12.846300",
        NE: "-16.724238, 22.985321",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Nauru",
        territoryAlternativeNames: "",
        territoryCountry: "Nauru",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "NR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "NRU",
        state_code: "",
        skratchCode: "",
        callingCodes: "674",
        lat: "-0.5228",
        lng: "166.9315",
        SW: "-0.558535, 166.902193",
        NE: "-0.501027, 166.973096",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Nebraska",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USANE",
        state_code: "NE",
        skratchCode: "USANE",
        callingCodes: "",
        lat: "41.4925",
        lng: "-99.9018",
        SW: "39.747909, -104.231178",
        NE: "43.167460, -95.724104",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Nepal",
        territoryAlternativeNames: "",
        territoryCountry: "Nepal",
        territoryWRegion: "South Asia",
        territoryType: "Country",
        alpha2Code: "NP",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "NPL",
        state_code: "",
        skratchCode: "",
        callingCodes: "977",
        lat: "28.3949",
        lng: "84.1240",
        SW: "27.333233, 79.786062",
        NE: "28.462333, 88.968845",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Netherlands",
        territoryAlternativeNames: "",
        territoryCountry: "Netherlands",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "NL",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "NLD",
        state_code: "",
        skratchCode: "",
        callingCodes: "31",
        lat: "52.1326",
        lng: "5.2913",
        SW: "51.065995, 3.347579",
        NE: "53.399972, 7.713487",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Nevada",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USANV",
        state_code: "NV",
        skratchCode: "USANV",
        callingCodes: "",
        lat: "38.8026",
        lng: "-116.4194",
        SW: "35.438517, -119.969622",
        NE: "42.234449, -111.782033",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "New Caledonia",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "NCL",
        state_code: "",
        skratchCode: "",
        callingCodes: "687",
        lat: "-21.402722",
        lng: "165.479777",
        SW: "-22.677663, 163.533162",
        NE: "-19.540280, 167.522316",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "New Hampshire",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USANH",
        state_code: "NH",
        skratchCode: "USANH",
        callingCodes: "",
        lat: "43.1939",
        lng: "-71.5724",
        SW: "42.670343, -72.735722",
        NE: "45.274448, -70.688314",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "New Jersey",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USANJ",
        state_code: "NJ",
        skratchCode: "USANJ",
        callingCodes: "",
        lat: "40.0583",
        lng: "-74.4057",
        SW: "39.019919, -75.656008",
        NE: "41.018513, -73.526582",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "New Mexico",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USANM",
        state_code: "NM",
        skratchCode: "USANM",
        callingCodes: "",
        lat: "34.5199",
        lng: "-105.8701",
        SW: "31.073732, -109.462722",
        NE: "37.130828, -102.563161",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "New York",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USANY",
        state_code: "NY",
        skratchCode: "USANY",
        callingCodes: "",
        lat: "40.7128",
        lng: "-74.0060",
        SW: "40.470127, -74.314623",
        NE: "40.901859, -73.653340",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "New Zealand",
        territoryAlternativeNames: "",
        territoryCountry: "New Zealand",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "NZ",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "NZL",
        state_code: "",
        skratchCode: "",
        callingCodes: "64",
        lat: "-43.106872",
        lng: "171.447111",
        SW: "-47.946564, 164.775369",
        NE: "-33.838403, -178.239487",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Nicaragua",
        territoryAlternativeNames: "",
        territoryCountry: "Nicaragua",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "NI",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "NIC",
        state_code: "",
        skratchCode: "",
        callingCodes: "505",
        lat: "12.8654",
        lng: "-85.2072",
        SW: "10.778639, -87.415210",
        NE: "14.963356, -82.536512",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Niger",
        territoryAlternativeNames: "",
        territoryCountry: "Niger",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "NE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "NER",
        state_code: "",
        skratchCode: "",
        callingCodes: "227",
        lat: "17.6078",
        lng: "8.0817",
        SW: "11.397215, 0.527927",
        NE: "23.410238, 17.604993",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Nigeria",
        territoryAlternativeNames: "",
        territoryCountry: "Nigeria",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "NG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "NGA",
        state_code: "",
        skratchCode: "",
        callingCodes: "234",
        lat: "9.0820",
        lng: "8.6753",
        SW: "4.360407, 2.453583",
        NE: "13.785178, 16.000200",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Niue",
        territoryAlternativeNames: "",
        territoryCountry: "New Zealand",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "NZL",
        alpha3Code: "NIU",
        state_code: "",
        skratchCode: "",
        callingCodes: "683",
        lat: "-19.0544",
        lng: "-169.8672",
        SW: "-19.157936, -169.951485",
        NE: "-18.953365, -169.753766",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Norfolk Island",
        territoryAlternativeNames: "",
        territoryCountry: "Australia",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "AUS",
        alpha3Code: "NFK",
        state_code: "",
        skratchCode: "",
        callingCodes: "672",
        lat: "-29.0408",
        lng: "167.9547",
        SW: "-29.136909, 167.902088",
        NE: "-28.995491, 168.015650",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "North Carolina",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USANC",
        state_code: "NC",
        skratchCode: "USANC",
        callingCodes: "",
        lat: "35.7596",
        lng: "-79.0193",
        SW: "34.268129, -84.653116",
        NE: "36.715445, -74.784832",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "North Dakota",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAND",
        state_code: "ND",
        skratchCode: "USAND",
        callingCodes: "",
        lat: "47.5515",
        lng: "-101.0020",
        SW: "45.601507, -104.258268",
        NE: "49.067174, -96.225561",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "North Korea",
        territoryAlternativeNames: "",
        territoryCountry: "North Korea",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "KP",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "PRK",
        state_code: "",
        skratchCode: "",
        callingCodes: "850",
        lat: "40.3399",
        lng: "127.5101",
        SW: "37.553062, 124.481045",
        NE: "42.147799, 130.790371",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "North Macedonia",
        territoryAlternativeNames: "",
        territoryCountry: "Macedonia",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "MK",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MKD",
        state_code: "",
        skratchCode: "",
        callingCodes: "389",
        lat: "41.6086",
        lng: "21.7453",
        SW: "40.724566, 20.495087",
        NE: "42.474643, 23.779049",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Northern Cyprus",
        territoryAlternativeNames: "",
        territoryCountry: "Cyprus",
        territoryWRegion: "Europe",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "CYP",
        alpha3Code: "NOCYP",
        state_code: "",
        skratchCode: "",
        callingCodes: "90",
        lat: "35.2480",
        lng: "33.6577",
        SW: "35.080479, 32.674767",
        NE: "35.662350, 34.735335",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Northern Ireland",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "IX",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "NIR",
        state_code: "",
        skratchCode: "",
        callingCodes: "",
        lat: "54.7877",
        lng: "-6.4923",
        SW: "54.058394, -8.210155",
        NE: "55.163676, -5.027770",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Northern Mariana Islands",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USANI",
        state_code: "",
        skratchCode: "USANM",
        callingCodes: "1",
        lat: "15.0979",
        lng: "145.6739",
        SW: "14.025617, 144.293888",
        NE: "18.617139, 146.899060",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Norway",
        territoryAlternativeNames: "",
        territoryCountry: "Norway",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "NO",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "NOR",
        state_code: "",
        skratchCode: "",
        callingCodes: "47",
        lat: "60.4720",
        lng: "8.4689",
        SW: "58.177120, 2.479810",
        NE: "68.568946, 31.948313",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Ohio",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAOH",
        state_code: "OH",
        skratchCode: "USAOH",
        callingCodes: "",
        lat: "40.4173",
        lng: "-82.9071",
        SW: "38.423443, -84.976961",
        NE: "42.061950, -80.248919",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Oklahoma",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAOK",
        state_code: "OK",
        skratchCode: "USAOK",
        callingCodes: "",
        lat: "35.0078",
        lng: "-97.0929",
        SW: "34.157544, -103.000638",
        NE: "37.062763, -94.225018",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Oman",
        territoryAlternativeNames: "",
        territoryCountry: "Oman",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "OM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "OMN",
        state_code: "",
        skratchCode: "",
        callingCodes: "968",
        lat: "21.4735",
        lng: "55.9754",
        SW: "15.931136, 52.659368",
        NE: "23.782336, 60.961050",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Oregon",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAOR",
        state_code: "",
        skratchCode: "",
        callingCodes: "",
        lat: "43.8041",
        lng: "-120.5542",
        SW: "41.822084, -124.704774",
        NE: "45.997341, -115.710594",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Pakistan",
        territoryAlternativeNames: "",
        territoryCountry: "Pakistan",
        territoryWRegion: "South Asia",
        territoryType: "Country",
        alpha2Code: "PK",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "PAK",
        state_code: "",
        skratchCode: "",
        callingCodes: "92",
        lat: "30.3753",
        lng: "69.3451",
        SW: "4.319710, 60.957961",
        NE: "32.516228, 79.144695",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Palau",
        territoryAlternativeNames: "",
        territoryCountry: "Palau",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "PW",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "PLW",
        state_code: "",
        skratchCode: "",
        callingCodes: "680",
        lat: "7.515",
        lng: "134.5825",
        SW: "2.738153, 131.227239",
        NE: "7.906793, 135.474703",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Palestine",
        territoryAlternativeNames: "",
        territoryCountry: "Palestine",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "PSE",
        alpha3Code: "PSE",
        state_code: "",
        skratchCode: "",
        callingCodes: "970",
        lat: "31.9522",
        lng: "35.2332",
        SW: "31.297987, 34.820459",
        NE: "32.473484, 35.677842",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Panama",
        territoryAlternativeNames: "",
        territoryCountry: "Panama",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "PA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "PAN",
        state_code: "",
        skratchCode: "",
        callingCodes: "507",
        lat: "8.5380",
        lng: "-80.7821",
        SW: "7.359351, -83.106666",
        NE: "9.398731, -77.105725",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Papua New Guinea",
        territoryAlternativeNames: "",
        territoryCountry: "Papua New Guinea",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "PG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "PNG",
        state_code: "",
        skratchCode: "",
        callingCodes: "675",
        lat: "-6.3150",
        lng: "143.9555",
        SW: "-10.110157, 140.857917",
        NE: "-3.835096, 156.399006",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Paraguay",
        territoryAlternativeNames: "",
        territoryCountry: "Paraguay",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "PY",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "PRY",
        state_code: "",
        skratchCode: "",
        callingCodes: "595",
        lat: "-23.4425",
        lng: "-58.4438",
        SW: "-27.639315, -62.076855",
        NE: "-19.273312, -53.462999",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Pennsylvania",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAPA",
        state_code: "PA",
        skratchCode: "USAPA",
        callingCodes: "",
        lat: "41.2033",
        lng: "-77.1945",
        SW: "39.559944, -80.675811",
        NE: "41.827515, -74.227632",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Peru",
        territoryAlternativeNames: "",
        territoryCountry: "Peru",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "PE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "PER",
        state_code: "",
        skratchCode: "",
        callingCodes: "51",
        lat: "-9.1900",
        lng: "-75.0152",
        SW: "-18.268241, -79.138212",
        NE: "-0.569581, -68.330727",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Philippines",
        territoryAlternativeNames: "",
        territoryCountry: "Philippines",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "PH",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "PHL",
        state_code: "",
        skratchCode: "",
        callingCodes: "63",
        lat: "12.8797",
        lng: "121.7740",
        SW: "7.482134, 116.708386",
        NE: "17.657958, 129.897956",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Pitcairn Islands",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "PCN",
        state_code: "",
        skratchCode: "",
        callingCodes: "64",
        lat: "-24.3768",
        lng: "-128.3242",
        SW: "-25.178525, -130.265414",
        NE: "-24.062009, -127.762673",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Poland",
        territoryAlternativeNames: "",
        territoryCountry: "Poland",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "PL",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "POL",
        state_code: "",
        skratchCode: "",
        callingCodes: "48",
        lat: "51.9194",
        lng: "19.1451",
        SW: "49.515122, 13.831891",
        NE: "54.408827, 25.396347",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Portugal",
        territoryAlternativeNames: "",
        territoryCountry: "Portugal",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "PT",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "PRT",
        state_code: "",
        skratchCode: "",
        callingCodes: "351",
        lat: "39.5",
        lng: "-8.000000",
        SW: "36.982888, -10.082098",
        NE: "41.875674, -5.311622",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Puerto Rico",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "PRI",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "18.2208",
        lng: "-66.5901",
        SW: "17.907597, -67.449508",
        NE: "18.593566, -65.332643",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Qatar",
        territoryAlternativeNames: "",
        territoryCountry: "Qatar",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "QA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "QAT",
        state_code: "",
        skratchCode: "",
        callingCodes: "974",
        lat: "25.3548",
        lng: "51.1839",
        SW: "24.375287, 50.581803",
        NE: "26.088509, 52.024268",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Republic of Congo",
        territoryAlternativeNames: "",
        territoryCountry: "Republic of Congo",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "CG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "COG",
        state_code: "",
        skratchCode: "",
        callingCodes: "242",
        lat: "-1.505178",
        lng: "15.486076",
        SW: "-5.123864, 10.672624",
        NE: "3.729658, 19.708410",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Réunion",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "Africa",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "REU",
        state_code: "",
        skratchCode: "",
        callingCodes: "262",
        lat: "-21.1151",
        lng: "55.5364",
        SW: "-21.383628, 55.165937",
        NE: "-20.859348, 55.927732",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Rhode Island",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USARI",
        state_code: "RI",
        skratchCode: "USARI",
        callingCodes: "",
        lat: "41.5801",
        lng: "-71.4774",
        SW: "41.255775, -71.929855",
        NE: "42.034211, -71.210880",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Romania",
        territoryAlternativeNames: "",
        territoryCountry: "Romania",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "RO",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ROU",
        state_code: "",
        skratchCode: "",
        callingCodes: "40",
        lat: "45.9432",
        lng: "24.9668",
        SW: "43.740531, 21.204900",
        NE: "47.952299, 30.407785",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Russia",
        territoryAlternativeNames: "",
        territoryCountry: "Russia",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "RU",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "RUS",
        state_code: "",
        skratchCode: "",
        callingCodes: "7",
        lat: "61.5240",
        lng: "105.3188",
        SW: "42.802511, 36.869298",
        NE: "59.958891, 162.658315",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Rwanda",
        territoryAlternativeNames: "",
        territoryCountry: "Rwanda",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "RW",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "RWA",
        state_code: "",
        skratchCode: "",
        callingCodes: "250",
        lat: "-1.9403",
        lng: "29.8739",
        SW: "-2.825560, 28.874259",
        NE: "-1.097198, 30.939964",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Saint Barthélemy",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "BLM",
        state_code: "",
        skratchCode: "",
        callingCodes: "590",
        lat: "17.9000",
        lng: "-62.8333",
        SW: "17.876075, -62.911972",
        NE: "17.950493, -62.775946",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Saint Helena, Ascension and Tristan da Cunha",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Africa",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "SHN",
        state_code: "",
        skratchCode: "",
        callingCodes: "290",
        lat: "-24.1435",
        lng: "-10.0307",
        SW: "-24.201827, -10.147488",
        NE: "-23.983392, -9.886222",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Saint Kitts and Nevis",
        territoryAlternativeNames: "",
        territoryCountry: "Saint Kitts and Nevis",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "KN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "KNA",
        state_code: "",
        skratchCode: "",
        callingCodes: "1869",
        lat: "17.3578",
        lng: "-62.7830",
        SW: "17.090179, -62.874425",
        NE: "17.409365, -62.501129",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Saint Lucia",
        territoryAlternativeNames: "",
        territoryCountry: "Saint Lucia",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "LC",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LCA",
        state_code: "",
        skratchCode: "",
        callingCodes: "1758",
        lat: "13.9094",
        lng: "-60.9789",
        SW: "13.720831, -61.111290",
        NE: "14.102682, -60.815643",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Saint Martin",
        territoryAlternativeNames: "",
        territoryCountry: "Saint Martin",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "MF",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "MAF",
        state_code: "",
        skratchCode: "",
        callingCodes: "590",
        lat: "18.0708",
        lng: "-63.0501",
        SW: "18.038385, -63.162738",
        NE: "18.136880, -62.957349",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Saint Pierre and Miquelon",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "North America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "SPM",
        state_code: "",
        skratchCode: "",
        callingCodes: "508",
        lat: "46.8852",
        lng: "-56.3159",
        SW: "46.728019, -56.486586",
        NE: "47.123053, -56.159667",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Saint Vincent and the Grenadines",
        territoryAlternativeNames: "",
        territoryCountry: "Saint Vincent and the Grenadines",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "VC",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "VCT",
        state_code: "",
        skratchCode: "",
        callingCodes: "1784",
        lat: "13.242731",
        lng: "-61.193586",
        SW: "12.591254, -61.574572",
        NE: "13.372673, -60.988047",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Samoa",
        territoryAlternativeNames: "",
        territoryCountry: "Samoa",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "WS",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "WSM",
        state_code: "",
        skratchCode: "",
        callingCodes: "685",
        lat: "-13.665705",
        lng: "-172.432782",
        SW: "-13.969894, -172.864136",
        NE: "-13.530472, -171.225155",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "San Marino",
        territoryAlternativeNames: "",
        territoryCountry: "San Marino",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "SM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SMR",
        state_code: "",
        skratchCode: "",
        callingCodes: "378",
        lat: "43.9424",
        lng: "12.4578",
        SW: "43.892673, 12.393141",
        NE: "43.995651, 12.553651",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Sao Tome and Principe",
        territoryAlternativeNames: "",
        territoryCountry: "Sao Tome and Principe",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "ST",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "STP",
        state_code: "",
        skratchCode: "",
        callingCodes: "239",
        lat: "0.1864",
        lng: "6.6131",
        SW: "-0.074284, 6.212772",
        NE: "1.745649, 7.728097",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Saudi Arabia",
        territoryAlternativeNames: "",
        territoryCountry: "Saudi Arabia",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "SA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SAU",
        state_code: "",
        skratchCode: "",
        callingCodes: "966",
        lat: "23.8859",
        lng: "45.0792",
        SW: "16.858348, 36.592181",
        NE: "31.564027, 52.771726",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Scotland",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "EX",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "SCT",
        state_code: "",
        skratchCode: "",
        callingCodes: "",
        lat: "56.4907",
        lng: "-4.2026",
        SW: "54.710950, -5.545367",
        NE: "59.250211, -1.544910",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Senegal",
        territoryAlternativeNames: "",
        territoryCountry: "Senegal",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "SN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SEN",
        state_code: "",
        skratchCode: "",
        callingCodes: "221",
        lat: "14.4974",
        lng: "-14.4524",
        SW: "12.133718, -17.350004",
        NE: "16.556908, -11.611015",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Serbia",
        territoryAlternativeNames: "",
        territoryCountry: "Serbia",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "RS",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "SRB",
        state_code: "",
        skratchCode: "",
        callingCodes: "381",
        lat: "44.0165",
        lng: "21.0059",
        SW: "42.073676, 18.891732",
        NE: "45.976203, 23.262553",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Seychelles",
        territoryAlternativeNames: "",
        territoryCountry: "Seychelles",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "SC",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SYC",
        state_code: "",
        skratchCode: "",
        callingCodes: "248",
        lat: "-4.6796",
        lng: "55.4920",
        SW: "-4.957207, 55.026894",
        NE: "-4.193416, 56.050743",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Sierra Leone",
        territoryAlternativeNames: "",
        territoryCountry: "Sierra Leone",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "SL",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SLE",
        state_code: "",
        skratchCode: "",
        callingCodes: "232",
        lat: "8.4606",
        lng: "-11.7799",
        SW: "7.022427, -13.287767",
        NE: "9.984371, -10.285747",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Singapore",
        territoryAlternativeNames: "",
        territoryCountry: "Singapore",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "SG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SGP",
        state_code: "",
        skratchCode: "",
        callingCodes: "65",
        lat: "1.3521",
        lng: "103.8198",
        SW: "1.179428, 103.583303",
        NE: "1.466460, 104.064901",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Sint Maarten",
        territoryAlternativeNames: "",
        territoryCountry: "Sint Maarten",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "SX",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SXM",
        state_code: "",
        skratchCode: "",
        callingCodes: "1721",
        lat: "18.0425",
        lng: "-63.0548",
        SW: "17.993324, -63.152436",
        NE: "18.066098, -62.989683",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Slovakia",
        territoryAlternativeNames: "",
        territoryCountry: "Slovakia",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "SK",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SVK",
        state_code: "",
        skratchCode: "",
        callingCodes: "421",
        lat: "48.6690",
        lng: "19.6990",
        SW: "47.575189, 17.026021",
        NE: "49.559936, 22.919601",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Slovenia",
        territoryAlternativeNames: "",
        territoryCountry: "Slovenia",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "SI",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SVN",
        state_code: "",
        skratchCode: "",
        callingCodes: "386",
        lat: "46.1512",
        lng: "14.9955",
        SW: "45.377449, 13.411121",
        NE: "46.834103, 16.556343",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Solomon Islands",
        territoryAlternativeNames: "",
        territoryCountry: "Solomon Islands",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "SB",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SLB",
        state_code: "",
        skratchCode: "",
        callingCodes: "677",
        lat: "-9.6457",
        lng: "160.1562",
        SW: "-11.603432, 155.122951",
        NE: "-5.718946, 163.114244",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Somalia",
        territoryAlternativeNames: "",
        territoryCountry: "Somalia",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "SO",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "SOM",
        state_code: "",
        skratchCode: "",
        callingCodes: "252",
        lat: "5.1521",
        lng: "46.1996",
        SW: "-1.310901, 38.696882",
        NE: "11.827357, 52.991013",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Somaliland",
        territoryAlternativeNames: "",
        territoryCountry: "Somalia",
        territoryWRegion: "Africa",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "SOM",
        alpha3Code: "SOMLN",
        state_code: "",
        skratchCode: "",
        callingCodes: "252",
        lat: "9.4117",
        lng: "46.8253",
        SW: "8.762368, 42.415695",
        NE: "11.746535, 49.337075",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "South Africa",
        territoryAlternativeNames: "",
        territoryCountry: "South Africa",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "ZA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ZAF",
        state_code: "",
        skratchCode: "SOMLN",
        callingCodes: "27",
        lat: "-30.5595",
        lng: "22.9375",
        SW: "-35.422438, 16.578932",
        NE: "-21.620060, 34.679574",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "South Carolina",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USASC",
        state_code: "SC",
        skratchCode: "",
        callingCodes: "",
        lat: "33.8361",
        lng: "-81.1637",
        SW: "32.203331, -83.138049",
        NE: "34.931027, -77.907252",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "South Dakota",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USASD",
        state_code: "SD",
        skratchCode: "USASC",
        callingCodes: "",
        lat: "43.9695",
        lng: "-99.9018",
        SW: "42.746507, -104.402755",
        NE: "45.962658, -96.066841",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "South Korea",
        territoryAlternativeNames: "",
        territoryCountry: "South Korea",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "KR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "KOR",
        state_code: "",
        skratchCode: "USASD",
        callingCodes: "82",
        lat: "35.9078",
        lng: "127.7669",
        SW: "34.326836, 125.640324",
        NE: "38.559027, 129.897635",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "South Ossetia",
        territoryAlternativeNames: "",
        territoryCountry: "Georgia",
        territoryWRegion: "Middle East",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GEO",
        alpha3Code: "GEOSO",
        state_code: "",
        skratchCode: "",
        callingCodes: "7",
        lat: "42.362093",
        lng: "44.071444",
        SW: "42.063119, 43.559869",
        NE: "42.711947, 45.156808",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "South Sudan",
        territoryAlternativeNames: "",
        territoryCountry: "South Sudan",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "SS",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SSD",
        state_code: "",
        skratchCode: "GEOSO",
        callingCodes: "211",
        lat: "6.8770",
        lng: "31.3070",
        SW: "3.800115, 25.195990",
        NE: "11.340268, 35.430432",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Spain",
        territoryAlternativeNames: "",
        territoryCountry: "Spain",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "ES",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "ESP",
        state_code: "",
        skratchCode: "",
        callingCodes: "34",
        lat: "40.4637",
        lng: "-3.7492",
        SW: "36.390402, -9.417602",
        NE: "42.343979, 4.263480",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Sri Lanka",
        territoryAlternativeNames: "",
        territoryCountry: "Sri Lanka",
        territoryWRegion: "South Asia",
        territoryType: "Country",
        alpha2Code: "LK",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "LKA",
        state_code: "",
        skratchCode: "",
        callingCodes: "94",
        lat: "7.8731",
        lng: "80.7718",
        SW: "5.845631, 79.272034",
        NE: "9.793398, 82.001174",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Sudan",
        territoryAlternativeNames: "",
        territoryCountry: "Sudan",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "SD",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SDN",
        state_code: "",
        skratchCode: "",
        callingCodes: "249",
        lat: "12.8628",
        lng: "30.2176",
        SW: "9.661708, 22.560241",
        NE: "21.877356, 37.692404",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Suriname",
        territoryAlternativeNames: "",
        territoryCountry: "Suriname",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "SR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SUR",
        state_code: "",
        skratchCode: "",
        callingCodes: "597",
        lat: "3.9193",
        lng: "-56.0278",
        SW: "1.828995, -57.779414",
        NE: "6.019568, -53.677553",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Svalbard",
        territoryAlternativeNames: "",
        territoryCountry: "Norway",
        territoryWRegion: "Europe",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "NOR",
        alpha3Code: "SJM",
        state_code: "",
        skratchCode: "",
        callingCodes: "47",
        lat: "77.8750",
        lng: "20.9752",
        SW: "77.092463, 7.884221",
        NE: "79.653571, 35.781411",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Sweden",
        territoryAlternativeNames: "",
        territoryCountry: "Sweden",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "SE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SWE",
        state_code: "",
        skratchCode: "",
        callingCodes: "46",
        lat: "60.934493",
        lng: "14.823753",
        SW: "55.873165, 9.950157",
        NE: "67.729238, 26.733268",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Switzerland",
        territoryAlternativeNames: "",
        territoryCountry: "Switzerland",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "CH",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "CHE",
        state_code: "",
        skratchCode: "",
        callingCodes: "41",
        lat: "46.8182",
        lng: "8.2275",
        SW: "45.869214, 6.032799",
        NE: "47.555343, 10.343241",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Syria",
        territoryAlternativeNames: "",
        territoryCountry: "Syria",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "SY",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "SYR",
        state_code: "",
        skratchCode: "",
        callingCodes: "963",
        lat: "34.8021",
        lng: "38.9968",
        SW: "32.401867, 35.645094",
        NE: "37.229362, 42.515976",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Taiwan",
        territoryAlternativeNames: "",
        territoryCountry: "China",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "CHN",
        alpha3Code: "TWN",
        state_code: "",
        skratchCode: "",
        callingCodes: "886",
        lat: "23.6978",
        lng: "120.9605",
        SW: "21.932344, 119.853281",
        NE: "25.248250, 122.427555",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Tajikistan",
        territoryAlternativeNames: "",
        territoryCountry: "Tajikistan",
        territoryWRegion: "Central Asia",
        territoryType: "Country",
        alpha2Code: "TJ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TJK",
        state_code: "",
        skratchCode: "",
        callingCodes: "992",
        lat: "38.8610",
        lng: "71.2761",
        SW: "36.596960, 67.519610",
        NE: "39.497852, 74.762870",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Tanzania",
        territoryAlternativeNames: "",
        territoryCountry: "Tanzania",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "TZ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TZA",
        state_code: "",
        skratchCode: "",
        callingCodes: "255",
        lat: "-6.3690",
        lng: "34.8888",
        SW: "-11.664854, 30.132980",
        NE: "-1.820282, 41.150923",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Tennessee",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USATN",
        state_code: "TN",
        skratchCode: "",
        callingCodes: "",
        lat: "35.5175",
        lng: "-86.5804",
        SW: "34.780068, -90.482246",
        NE: "36.735641, -81.588463",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Texas",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USATX",
        state_code: "TX",
        skratchCode: "USATN",
        callingCodes: "",
        lat: "31.9686",
        lng: "-99.9018",
        SW: "26.419877, -105.456329",
        NE: "35.561318, -92.250860",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Thailand",
        territoryAlternativeNames: "",
        territoryCountry: "Thailand",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "TH",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "THA",
        state_code: "",
        skratchCode: "USATX",
        callingCodes: "66",
        lat: "15.8700",
        lng: "100.9925",
        SW: "6.287855, 97.080347",
        NE: "19.336055, 105.910469",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Timor-Leste",
        territoryAlternativeNames: "",
        territoryCountry: "Timor-Leste",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "TL",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TLS",
        state_code: "",
        skratchCode: "",
        callingCodes: "670",
        lat: "-8.8742",
        lng: "125.7275",
        SW: "-9.493524, 124.932485",
        NE: "-8.128664, 127.406071",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Togo",
        territoryAlternativeNames: "",
        territoryCountry: "Togo",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "TG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TGO",
        state_code: "",
        skratchCode: "",
        callingCodes: "228",
        lat: "8.6195",
        lng: "0.8248",
        SW: "5.965238, 0.065701",
        NE: "11.156327, 1.455947",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Tokelau",
        territoryAlternativeNames: "",
        territoryCountry: "New Zealand",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "NZL",
        alpha3Code: "TKL",
        state_code: "",
        skratchCode: "",
        callingCodes: "690",
        lat: "-9.2002",
        lng: "-171.8484",
        SW: "-9.386902, -172.605944",
        NE: "-8.545563, -171.160016",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "No",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Tonga",
        territoryAlternativeNames: "",
        territoryCountry: "Tonga",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "TO",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TON",
        state_code: "",
        skratchCode: "",
        callingCodes: "676",
        lat: "-21.1790",
        lng: "-175.1982",
        SW: "-21.427929, -175.522746",
        NE: "-18.550504, -173.657451",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Transnistria",
        territoryAlternativeNames: "",
        territoryCountry: "Moldova",
        territoryWRegion: "Europe",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "MDA",
        alpha3Code: "MDATN",
        state_code: "",
        skratchCode: "",
        callingCodes: "373",
        lat: "47.2153",
        lng: "29.4638",
        SW: "46.552816, 28.124979",
        NE: "48.315324, 29.975370",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Trinidad and Tobago",
        territoryAlternativeNames: "",
        territoryCountry: "Trinidad and Tobago",
        territoryWRegion: "Central America",
        territoryType: "Country",
        alpha2Code: "TT",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TTO",
        state_code: "",
        skratchCode: "MDATN",
        callingCodes: "1868",
        lat: "10.6918",
        lng: "-61.2225",
        SW: "10.031851, -61.997055",
        NE: "11.372091, -60.350806",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Tunisia",
        territoryAlternativeNames: "",
        territoryCountry: "Tunisia",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "TN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TUN",
        state_code: "",
        skratchCode: "",
        callingCodes: "216",
        lat: "33.8869",
        lng: "9.5375",
        SW: "30.471243, 7.621811",
        NE: "37.217335, 12.196916",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Turkey",
        territoryAlternativeNames: "",
        territoryCountry: "Turkey",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "TR",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TUR",
        state_code: "",
        skratchCode: "",
        callingCodes: "90",
        lat: "38.9637",
        lng: "35.2433",
        SW: "36.114635, 26.527228",
        NE: "41.327663, 43.870615",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Turkmenistan",
        territoryAlternativeNames: "",
        territoryCountry: "Turkmenistan",
        territoryWRegion: "Central Asia",
        territoryType: "Country",
        alpha2Code: "TM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TKM",
        state_code: "",
        skratchCode: "",
        callingCodes: "993",
        lat: "38.9697",
        lng: "59.5563",
        SW: "37.287108, 53.127021",
        NE: "40.451487, 67.873415",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Turks and Caicos Islands",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "TCA",
        state_code: "",
        skratchCode: "",
        callingCodes: "44",
        lat: "21.6940",
        lng: "-71.7979",
        SW: "21.501170, -72.597673",
        NE: "21.867438, -71.087979",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Tuvalu",
        territoryAlternativeNames: "",
        territoryCountry: "Tuvalu",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "TV",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "TUV",
        state_code: "",
        skratchCode: "",
        callingCodes: "688",
        lat: "-8.506815",
        lng: "179.115818",
        SW: "-8.659014, 179.017044",
        NE: "-8.412975, 179.247512",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Uganda",
        territoryAlternativeNames: "",
        territoryCountry: "Uganda",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "UG",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "UGA",
        state_code: "",
        skratchCode: "",
        callingCodes: "256",
        lat: "1.3733",
        lng: "32.2903",
        SW: "-1.474154, 29.243573",
        NE: "4.021830, 35.432449",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Ukraine",
        territoryAlternativeNames: "",
        territoryCountry: "Ukraine",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "UA",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "UKR",
        state_code: "",
        skratchCode: "",
        callingCodes: "380",
        lat: "48.3794",
        lng: "31.1656",
        SW: "46.258998, 22.402506",
        NE: "49.800043, 40.894548",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "United Arab Emirates",
        territoryAlternativeNames: "",
        territoryCountry: "United Arab Emirates",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "AE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ARE",
        state_code: "",
        skratchCode: "",
        callingCodes: "971",
        lat: "23.4241",
        lng: "53.8478",
        SW: "22.774649, 51.863653",
        NE: "25.918909, 56.713131",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "United Kingdom",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "GB",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "GBR",
        state_code: "",
        skratchCode: "",
        callingCodes: "44",
        lat: "53.636077",
        lng: "-1.793930",
        SW: "49.883880, -6.735882",
        NE: "59.273900, 0.032492",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "United States",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "Country",
        alpha2Code: "US",
        has_child_territories: "YES",
        parentCountry: "",
        alpha3Code: "USA",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "37.0902",
        lng: "-95.7129",
        SW: "29.326715, -118.595223",
        NE: "42.860564, -64.691049",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "United States Virgin Islands",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "Central America",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "VIR",
        state_code: "",
        skratchCode: "",
        callingCodes: "1",
        lat: "18.3358",
        lng: "-64.8963",
        SW: "18.282461, -65.094261",
        NE: "18.378886, -64.640510",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Uruguay",
        territoryAlternativeNames: "",
        territoryCountry: "Uruguay",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "UY",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "URY",
        state_code: "",
        skratchCode: "",
        callingCodes: "598",
        lat: "-32.5228",
        lng: "-55.7658",
        SW: "-35.150327, -58.686289",
        NE: "-30.003986, -53.352935",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Utah",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAUT",
        state_code: "UT",
        skratchCode: "USAUT",
        callingCodes: "",
        lat: "39.3210",
        lng: "-111.0937",
        SW: "36.634086, -114.595930",
        NE: "41.982554, -108.415750",
        "Flag?": "Temp",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Uzbekistan",
        territoryAlternativeNames: "",
        territoryCountry: "Uzbekistan",
        territoryWRegion: "Central Asia",
        territoryType: "Country",
        alpha2Code: "UZ",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "UZB",
        state_code: "",
        skratchCode: "",
        callingCodes: "998",
        lat: "41.3775",
        lng: "64.5853",
        SW: "39.092395, 56.221692",
        NE: "42.093432, 73.458784",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Vanuatu",
        territoryAlternativeNames: "",
        territoryCountry: "Vanuatu",
        territoryWRegion: "Oceania",
        territoryType: "Country",
        alpha2Code: "VU",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "VUT",
        state_code: "",
        skratchCode: "",
        callingCodes: "678",
        lat: "-15.3767",
        lng: "166.9592",
        SW: "-20.480331, 165.518023",
        NE: "-13.343138, 170.732070",
        "Flag?": "Temp",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Vatican City",
        territoryAlternativeNames: "",
        territoryCountry: "Vatican City",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "VAT",
        alpha3Code: "VAT",
        state_code: "",
        skratchCode: "",
        callingCodes: "379",
        lat: "41.9029",
        lng: "12.4534",
        SW: "41.899488, 12.446868",
        NE: "41.907281, 12.459894",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Venezuela",
        territoryAlternativeNames: "",
        territoryCountry: "Venezuela",
        territoryWRegion: "South America",
        territoryType: "Country",
        alpha2Code: "VE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "VEN",
        state_code: "",
        skratchCode: "",
        callingCodes: "58",
        lat: "6.4238",
        lng: "-66.5897",
        SW: "0.972685, -71.837037",
        NE: "10.727038, -59.108682",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Vermont",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAVT",
        state_code: "VT",
        skratchCode: "USAVT",
        callingCodes: "",
        lat: "44.5588",
        lng: "-72.5778",
        SW: "42.625266, -73.513359",
        NE: "45.096724, -71.151975",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Vietnam",
        territoryAlternativeNames: "",
        territoryCountry: "Vietnam",
        territoryWRegion: "East & Southeast Asia",
        territoryType: "Country",
        alpha2Code: "VN",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "VNM",
        state_code: "",
        skratchCode: "",
        callingCodes: "84",
        lat: "14.0583",
        lng: "108.2772",
        SW: "8.676584, 102.210544",
        NE: "21.228252, 109.266983",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Virginia",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAVA",
        state_code: "VA",
        skratchCode: "USAVA",
        callingCodes: "",
        lat: "37.4316",
        lng: "-78.6569",
        SW: "36.305904, -83.764075",
        NE: "39.249551, -75.556530",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Wales",
        territoryAlternativeNames: "",
        territoryCountry: "United Kingdom",
        territoryWRegion: "Europe",
        territoryType: "Country",
        alpha2Code: "WL",
        has_child_territories: "",
        parentCountry: "GBR",
        alpha3Code: "WLS",
        state_code: "",
        skratchCode: "",
        callingCodes: "",
        lat: "52.1307",
        lng: "-3.7837",
        SW: "51.447119, -5.544700",
        NE: "53.286219, -2.366371",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Wallis and Futuna",
        territoryAlternativeNames: "",
        territoryCountry: "France",
        territoryWRegion: "Oceania",
        territoryType: "Other Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "FRA",
        alpha3Code: "WLF",
        state_code: "",
        skratchCode: "",
        callingCodes: "681",
        lat: "-14.2938",
        lng: "-178.1165",
        SW: "-14.416615, -178.293858",
        NE: "-13.176523, -176.016122",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Washington",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAWA",
        state_code: "WA",
        skratchCode: "USAWA",
        callingCodes: "",
        lat: "47.7511",
        lng: "-120.7401",
        SW: "45.656951, -124.685302",
        NE: "49.055377, -116.266186",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Washington DC",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "DC",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "WDC",
        state_code: "",
        skratchCode: "",
        callingCodes: "",
        lat: "38.9072",
        lng: "-77.0369",
        SW: "38.820292, -77.131339",
        NE: "38.990981, -76.863031",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "West Virginia",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAWV",
        state_code: "WV",
        skratchCode: "USAWV",
        callingCodes: "",
        lat: "38.5976",
        lng: "-80.4549",
        SW: "37.083668, -82.650588",
        NE: "40.172487, -77.731868",
        "Flag?": "Yes",
        "Geojson?": "No",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Western Sahara",
        territoryAlternativeNames: "",
        territoryCountry: "Western Sahara",
        territoryWRegion: "Africa",
        territoryType: "Disputed Territory",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "ESH",
        alpha3Code: "ESH",
        state_code: "",
        skratchCode: "",
        callingCodes: "212",
        lat: "24.2155",
        lng: "-12.8858",
        SW: "20.932734, -17.623132",
        NE: "27.710570, -8.087108",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Wisconsin",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAWI",
        state_code: "WI",
        skratchCode: "USAWI",
        callingCodes: "",
        lat: "43.7844",
        lng: "-88.7879",
        SW: "42.461748, -92.902131",
        NE: "46.430748, -86.050120",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Wyoming",
        territoryAlternativeNames: "",
        territoryCountry: "United States",
        territoryWRegion: "North America",
        territoryType: "State",
        alpha2Code: "",
        has_child_territories: "",
        parentCountry: "USA",
        alpha3Code: "USAWY",
        state_code: "WY",
        skratchCode: "USAWY",
        callingCodes: "",
        lat: "43.0760",
        lng: "-107.2903",
        SW: "40.883459, -111.203958",
        NE: "45.127459, -103.769372",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Yemen",
        territoryAlternativeNames: "",
        territoryCountry: "Yemen",
        territoryWRegion: "Middle East",
        territoryType: "Country",
        alpha2Code: "YE",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "YEM",
        state_code: "",
        skratchCode: "",
        callingCodes: "967",
        lat: "15.5527",
        lng: "48.5164",
        SW: "12.210931, 43.066778",
        NE: "19.537934, 53.238236",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Zambia",
        territoryAlternativeNames: "",
        territoryCountry: "Zambia",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "ZM",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ZMB",
        state_code: "",
        skratchCode: "",
        callingCodes: "260",
        lat: "-13.1339",
        lng: "27.8493",
        SW: "-18.934081, 21.787476",
        NE: "-7.926142, 34.062712",
        "Flag?": "Yes",
        "Geojson?": "Yes",
        Outline: "Yes",
        field20: "",
        field21: "",
        field22: ""
    },
    {
        territoryName: "Zimbabwe",
        territoryAlternativeNames: "",
        territoryCountry: "Zimbabwe",
        territoryWRegion: "Africa",
        territoryType: "Country",
        alpha2Code: "ZW",
        has_child_territories: "",
        parentCountry: "",
        alpha3Code: "ZWE",
        state_code: "",
        skratchCode: "",
        callingCodes: "263",
        lat: "-19.0154",
        lng: "29.1549",
        SW: "-22.325092, 25.759155",
        NE: "-15.713787, 33.923988",
        "Flag?": "No",
        "Geojson?": "",
        Outline: "",
        field20: "",
        field21: "",
        field22: ""
    }
]
