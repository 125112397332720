import { Col, Radio, RadioChangeEvent, Row } from "antd";
import React from "react";

class ViewPickerComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e: RadioChangeEvent) {
        this.props.onViewSelectedChange(this.props.userSecret, e.target.value)
    }

    render() {
        return <>
            <Row justify="center">
                <Col span={24} style={{ textAlign: "center" }}>
                    <Radio.Group className="view-selector-holder" onChange={this.onChange} defaultValue={this.props.viewSelected}>
                        <Radio.Button className="slctr-btn" value="visited">Visited</Radio.Button>
                        <Radio.Button className="slctr-btn" value="want-to-visit">Want To Visit</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        </>
    }
}

export default ViewPickerComponent;
